import { Component, Inject, OnInit, OnDestroy } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormGroup } from '@angular/forms';

import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule, MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { User } from '@platform/shared';
import { AuthenticationService } from '@platform/shared/authentication';

import { LevelToTextPipe, MemberService } from '@mushindo/common';

import { ChangePasswordDialogComponent } from './change-password-dialog.component';

@Component({
  templateUrl: './profile-dialog.component.html',
  styleUrls: ['./profile-dialog.component.scss'],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    
    MatButtonModule,
    MatDialogModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,

    LevelToTextPipe,
  ],
})
export class ProfileDialogComponent implements OnInit, OnDestroy {
  protected currentUser?: any;
  protected currentMember?: any;

  protected form!: FormGroup;

  protected roleNames: string[] = [];

  private unsubscriber = new Subject<void>();

  constructor(
    protected authenticationService: AuthenticationService,
    protected memberService: MemberService,
    private readonly dialog: MatDialogRef<ProfileDialogComponent>,
    private readonly additionalDialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.currentUser = authenticationService.getCurrentUser();
    this.currentMember = memberService.getCurrentMember();

    if (this.currentUser?.roles) {
      this.roleNames = this.currentUser.roles.map((role: any) => role.name);
    }
  }

  public ngOnInit() {
    this.authenticationService.authenticationStateChange.pipe(takeUntil(this.unsubscriber)).subscribe((user?: User) => {
      this.currentUser = user;

      if (this.currentUser.roles) {
        this.roleNames = this.currentUser.roles.map((role: any) => role.name);
      }
    });
  }

  public ngOnDestroy(): void {
    this.unsubscriber.next();
    this.unsubscriber.complete();
  }

  protected submit() {
    this.dialog.close();
  }

  protected cancel() {
    this.dialog.close();
  }

  protected changePassword() {
    this.dialog.close();
    this.additionalDialog.open(ChangePasswordDialogComponent, { maxWidth: '30rem' });
  }
}
