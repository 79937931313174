import { ApplicationConfig, inject, provideZoneChangeDetection, provideAppInitializer } from '@angular/core';
import { provideHttpClient, withInterceptorsFromDi, HTTP_INTERCEPTORS } from '@angular/common/http';
import { provideRouter } from '@angular/router';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { provideServiceWorker } from '@angular/service-worker';

import { Breakpoints } from '@angular/cdk/layout';

import { ErrorInterceptor, PwaService, PWA_CONFIG } from '@public/core';
import { OAuth2Config, AuthorizationInterceptor } from '@public/core/authorization';
import { Sidenav, SIDENAV_CONFIG } from '@public/core/ui/sidenav';
import { Toolbar } from '@public/core/ui/toolbar';

import {
  AuthenticationConfig,
  NativeAuthenticationConfig,
  NativeAuthenticationProvider,
  FacebookAuthenticationConfig,
  FacebookAuthenticationProvider,
  GoogleAuthenticationConfig,
  GoogleAuthenticationProvider,
  LoginDialogConfig,
} from '@platform/shared/authentication';

import { environment } from '../environments/environment';

import { SidenavContentComponent, ToolbarContentComponent } from './components';

import { routes } from './root.routes';

import { QuillModule } from 'ngx-quill';

// Setup external authentication configuration. Replace the app ids below with your own as needed.

const nativeAuthenticationConfig: NativeAuthenticationConfig = {
  clientId: 'api',
  clientSecret: 'secret',
};

const facebookAuthenticationConfig: FacebookAuthenticationConfig = {
  clientId: '1338156956326798',
};

const googleAuthenticationConfig: GoogleAuthenticationConfig = {
  clientId: '193152156134-cke89djemn89ov284b8sas0eibeg18ii.apps.googleusercontent.com',
  oneTapEnabled: false,
};

const authenticationConfig: AuthenticationConfig = {
  autoLogin: false,
  providers: [NativeAuthenticationProvider, GoogleAuthenticationProvider],
  authorzation: {
    client: 'API',
    secret: 'secret',
  },
  messages: {
    loginSuccess: (name: string) => `Du er blevet logget ind som ${name}`,
    loginFailure: 'Der skete en fejl i forbindelse med login',
    logoutSuccess: 'Du er blevet logget ud',
    logoutFailure: 'Du er blevet logget ud',
    refreshSuccess: undefined,
    refreshFailure: 'Du er blevet logget ud på grund af inaktivitet',
  },
}

const loginDialogConfig: LoginDialogConfig = {
  labels: {
    title: 'Log ind med',
    username: 'Brugernavn',
    password: 'Adgangskode',
    alternative: 'eller',
    login: 'Log ind',
    cancel: 'Annullér',
  },
  messages: {
    loginSuccess: name => {
      return `Du er blevet logget ind som ${name}.`;
    },
    loginFailure: 'Der opstod en fejl i forbindelse med dit forsøg på at logge ind.',
    badCredentials: 'Fejl i brugernavn eller adgangskode.',
  },
};

// Disable animations if animations are not supported by the browser or if we are running on old
// version of iOS.

const disableAnimations =
  !('animate' in document.documentElement) ||
  (navigator && /iPhone OS (8|9|10|11|12|13)_|iPad OS/.test(navigator.userAgent));

const initSidenav = () => {
  const sidenav = inject(Sidenav);
  sidenav.content = SidenavContentComponent;
};

const initToolbar = () => {
  const toolbar = inject(Toolbar);
  toolbar.content = ToolbarContentComponent;
};

const initAuthentication = () => {
  const nativeAuthenticationConfig = inject(NativeAuthenticationProvider);
}

const initPwaService = () => {
  const pwaService = inject(PwaService);
};

export const rootConfig: ApplicationConfig = {
  providers: [
    provideZoneChangeDetection({ eventCoalescing: true }),
    provideRouter(routes),
    provideAnimationsAsync(),
    provideHttpClient(withInterceptorsFromDi()),
    provideServiceWorker('/ngsw-worker.js', {
      enabled: environment.production,
      registrationStrategy: 'registerImmediately',
    }),
    provideAppInitializer(initToolbar),
    provideAppInitializer(initSidenav),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthorizationInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true,
    },
    {
      provide: 'AuthenticationConfig',
      useValue: authenticationConfig as AuthenticationConfig,
    },
    { provide: 'NativeAuthenticationConfig', useValue: nativeAuthenticationConfig as NativeAuthenticationConfig },
    { provide: 'FacebookAuthenticationConfig', useValue: facebookAuthenticationConfig as FacebookAuthenticationConfig },
    { provide: 'GoogleAuthenticationConfig', useValue: googleAuthenticationConfig as GoogleAuthenticationConfig },
    {
      provide: LoginDialogConfig,
      useValue: loginDialogConfig,
    },
    {
      provide: SIDENAV_CONFIG,
      useValue: [Breakpoints.Handset, Breakpoints.TabletPortrait],
    },

    // PWA configuration. PWA_CONFIG value must be provided before the corresponding App Initializer.

    {
      provide: PWA_CONFIG,
      useValue: {
        installPrompt: {
          promptText: 'Vil du installere dette website som en app?',
          buttonText: 'Ja tak!',
        },
        updatePrompt: {
          promptText: 'En ny og opdateret version er tilgængelig',
          buttonText: 'Installér og genindlæs',
        },
        timeout: 10000,
      },
    },
    provideAppInitializer(initPwaService),
  ],
};
