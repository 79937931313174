<page-layout>
  <header color="primary">
    <header-content>
      <div>
        <h1>Cookie- og privatlivspolitik for {{ windowService.getHostname() }}</h1>
        <h2>Gældende fra 4. juli 2018</h2>
      </div>
    </header-content>
  </header>

  <main>
    <h2>Mushindos dataansvar</h2>

    <p>
      Vi behandler personoplysninger og har derfor vedtaget denne privatlivspolitik, der kort fortæller dig, hvordan vi
      behandler dine personoplysninger til sikring af en fair og gennemsigtig behandling.
    </p>

    <p>
      Gennemgående for vores databehandling er, at vi kun behandler personoplysninger til bestemte formål og ud fra
      berettigede (legitime) interesser. Vi behandler kun personoplysninger, der er relevante og nødvendige til
      opfyldelse af de angivne formål, og vi sletter dine oplysninger, når de ikke længere er nødvendige.
    </p>

    <h2>Kontaktoplysninger på den dataansvarlige</h2>

    <p>
      Mushindo er dataansvarlig, og vi sikrer, at dine personoplysninger behandles i overensstemmelse med lovgivningen.
      Forespørgsler vedr. vores indsamling og brug af personlige data kan rettes til:
    </p>

    <pre>
      Mushindo
      c/o Peter Berg
      Skovsbjergvej 12
      8680 Ry
    </pre>
    <pre>
      Telefon: 4040 2528
      Mail: webmaster&#64;mushindo.dk
      Website: https://mushindo.dk
    </pre>

    <h2>Behandling af personoplysninger</h2>

    <p>Vi behandler følgende personoplysninger om medlemmer:</p>

    <ol>
      <li>
        Almindelige personoplysninger: Registrerings- og kontaktoplysninger som navn, adresse, telefonnummer,
        e-mailadresse, køn, fødselsdato og indmeldelsesdato.
      </li>
      <li>
        Yderligere oplysinger: Oplysninger om fremmøde ved træning, gradueringer i såvel klub som andetsteds, deltagelse
        i aktiviteter iøvrigt.
      </li>
      <li>
        Personoplysninger, der er tillagt en højere grad af beskyttelse: Vi gemmer medlemmets CPR nummer med henblik på
        at opnå kommunalt tilskud.
      </li>
    </ol>

    <p>Om ledere og trænere behandler vi endvidere følgende oplysninger udover hvad vi behandler for medlemmer:</p>

    <ol>
      <li>CPR nummer samt oplysninger om strafbare forhold ved indhentelse af børneattest.</li>
    </ol>

    <h2>Vi behandler kun personoplysninger ud fra legitime interesser</h2>

    <p>
      I det omfang vi behandler dine medlemsoplysninger på baggrund af interesseafvejningsreglen, vil denne behandling
      udelukkende være motiveret af berettigede (legitime) interesser som:
    </p>

    <ul>
      <li>
        Udøvelse af idrætsaktivitet, herunder udfærdigelse af holdkort, holdopstillinger, interne resultatlister m.v.
      </li>
      <li>
        Håndtering af dine medlemsrettigheder i henhold til vedtægterne m.v., herunder i forhold til generalforsamling.
      </li>
      <li>Opfyldelse af medlemspligter, herunder opkrævning og betaling af kontingent m.v.</li>
      <li>Afholdelse af sociale arrangementer, sportslige aktiviteter samt andre aktiviteter.</li>
      <li>
        Brug af situationsbilleder taget i foreningen, der afbilder en konkret aktivitet eller situation i foreningen.
      </li>
      <li>
        Videregivelse af dine almindelige personoplysninger til specialforbund i DIF, til DGI samt til DGI’s
        landsdelskontorer, i relevant og nødvendigt omfang i forbindelse med idrætsaktivitet.
      </li>
      <li>
        Da foreningen er medlem af idrætsorganisationer, sker der videregivelse af oplysninger om ledere og træner til
        disse, for at ledere og trænere kan modtage information og aktivitets- og kursustilbud fra disse
        idrætsorganisationer.
      </li>
      <li>
        Af praktiske og administrative hensyn opbevarer vi dine almindelige medlemsoplysninger også i en periode efter
        din udmeldelse af foreningen.
      </li>
      <li>Af hensyn til kontaktmuligheder kan der for børn og unge under 18 år behandles oplysninger om forældrene.</li>
      <li>Bevaring af oplysninger med historisk værdi til statistik og lignende.</li>
    </ul>

    <h2>Samtykke</h2>

    <p>
      Oftest vil vores behandling af dine personoplysninger basere sig på et andet lovligt grundlag end samtykke. Vi
      indhenter derfor kun dit samtykke, når det i sjældne tilfælde er nødvendigt for at behandle dine personoplysninger
      til de formål, der er beskrevet ovenfor.
    </p>

    <p>
      Hvis vi indhenter dit samtykke, er det frivilligt, om du vil give samtykke, og du kan til enhver tid trække det
      tilbage ved at give os besked om det.
    </p>

    <p>
      Når vi indhenter personoplysninger om børn og unge, foretager vi en vurdering af, om barnet selv er i stand til at
      afgive de pågældende personoplysninger. Hvis ikke, indhenter vi samtykke fra en forælder. Vores udgangspunkt er 15
      år.
    </p>

    <p>
      Indsamler vi personoplysninger på børn via informationstjenester (apps og sociale medier), kan børn fra og med de
      er fyldt 13 år selv afgive samtykke.
    </p>

    <h2>Videregivelse af dine personoplysninger</h2>

    <p>
      I forbindelse med aktiviteter kan der ske videregivelse af oplysninger om deltagelse og resultater til relevante
      idrætsorganisationer.
    </p>

    <p>
      Der sker videregivelse af oplysninger om ledere og trænere i relevant omfang til idrætsorganisationer, som
      foreningen er medlem af.
    </p>

    <p>Vi videregiver ikke personoplysninger til firmaer til markedsføring uden dit samtykke.</p>

    <h2>Opbevaring og sletning af dine personoplysninger</h2>

    <p>
      Vi har forskellige behandlingsformål og opbevaringsperioder alt efter, om vi behandler dine personoplysninger som
      medlem af foreningen, som ulønnet leder eller træner eller som lønnet leder eller træner:
    </p>

    <h3>Medlemmer</h3>

    <p>
      Vi vil opbevare dine personoplysninger også efter medlemskabets ophør. Dette gør vi for at du senere vil kunne
      starte træningen igen på samme gradueringsniveau, både i Mushindo og i klubber som vi samarbejder, som du havde
      opnået da du stoppede. Ønsker du ikke at vi opbevarer disse oplysninger om dig, skal du henvende dig til Mushindos
      webmaster (se ovenfor).
    </p>
    <p>
      Såfremt du får slettet dine gradueringsoplysninger vil du ikke senere kunne påregne at kunne starte igen på det
      tidligere opnåede niveau.
    </p>

    <h3>Ulønnede ledere og trænere</h3>

    <p>
      Vi opbevarer dine personoplysninger efter ophøret af dit virke på lige fod med medlemsoplysninger. Dog opbevarer
      vi ikke personoplysninger, der er tillagt en højere grad af beskyttelse i mere end 1 år efter ophøret af dit
      virke.
    </p>

    <h2>Dine rettigheder</h2>

    <p>Du har en række særlige rettigheder efter persondataforordningen, når vi behandler personoplysninger om dig:</p>

    <ul>
      <li>Retten til at blive oplyst om behandlingen af data</li>
      <li>Retten til indsigt i egne personoplysninger</li>
      <li>Retten til berigtigelse</li>
      <li>Retten til sletning</li>
      <li>Retten til begrænsning af behandling</li>
      <li>Retten til dataportabilitet (udlevering af data i et almindeligt anvendt format)</li>
      <li>Retten til indsigelse</li>
    </ul>

    <p>
      Du kan gøre brug af dine rettigheder, herunder gøre indsigelse mod vores behandling, ved at henvende dig til os.
      Vores kontaktoplysninger finder du øverst. Hvis du f.eks. henvender dig med en anmodning om at få rettet eller
      slettet dine personoplysninger, undersøger vi, om betingelserne er opfyldt, og gennemfører i så fald ændringer
      eller sletning så hurtigt som muligt.
    </p>

    <p>Du kan altid indgive en klage til en databeskyttelsestilsynsmyndighed, f.eks. Datatilsynet.</p>

    <h2>Revidering af privatlivspolitikken</h2>

    <p>
      Vi forbeholder os retten til at foretage ændringer i denne privatlivspolitik fra tid til anden. Ved ændringer vil
      datoen øverst i privatlivspolitikken blive ændret. Den til enhver tid gældende privatlivspolitik vil være
      tilgængelig på vores websted. Ved væsentlige ændringer vil du modtage meddelelse herom.
    </p>
  </main>

  <footer color="tertiary">
    <footer-content></footer-content>
  </footer>
</page-layout>
