import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { Title } from '@angular/platform-browser';

import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';

import { MatRippleModule } from '@angular/material/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatDialog } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { AuthenticationService, LoginDialogComponent } from '@platform/shared/authentication';

import { MemberService } from '@mushindo/common';

import { AboutDialogComponent } from './about-dialog.component';
import { ProfileDialogComponent } from './profile-dialog.component';

@Component({
  templateUrl: './toolbar-content.component.html',
  styleUrls: ['./toolbar-content.component.scss'],
  imports: [
    CommonModule,
    RouterModule,

    MatButtonModule,
    MatDividerModule,
    MatIconModule,
    MatMenuModule,
    MatRippleModule,
  ],
})
export class ToolbarContentComponent implements OnInit, OnDestroy {
  protected isSmall = true;

  protected currentUser: any;
  protected currentMember: any;

  private unsubscriber = new Subject<void>();

  constructor(
    protected readonly titleService: Title,
    private readonly dialog: MatDialog,
    private readonly authenticationService: AuthenticationService,
    private readonly memberService: MemberService,
    private readonly breakpointObserver: BreakpointObserver
  ) {
    this.currentUser = this.authenticationService.getCurrentUser();
  }

  public ngOnInit() {
    this.currentUser = this.authenticationService.getCurrentUser();
    this.currentMember = this.memberService.getCurrentMember();

    this.authenticationService.authenticationStateChange.pipe(takeUntil(this.unsubscriber)).subscribe(user => {
      this.currentUser = user;
      this.currentMember = this.memberService.getCurrentMember();
    });

    this.breakpointObserver
      .observe(['(max-width: 600px)'])
      .pipe(takeUntil(this.unsubscriber))
      .subscribe((state: BreakpointState) => {
        this.isSmall = state.matches;
      });
  }

  public ngOnDestroy(): void {
    this.unsubscriber.next();
    this.unsubscriber.complete();
  }

  protected login() {
    this.dialog.open(LoginDialogComponent, { disableClose: true });
  }

  protected logout() {
    this.authenticationService.logout().subscribe();
  }

  protected about() {
    this.dialog.open(AboutDialogComponent);
  }

  protected profile() {
    this.dialog.open(ProfileDialogComponent, { maxWidth: '30rem', disableClose: true });
  }
}
