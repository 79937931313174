<page-layout>
  <header color="primary">
    <header-content>
    <div>
      <h1>Ups, noget gik galt :-(</h1>
      <h2>Siden findes ikke</h2>
    </div>
    </header-content>
  </header>

  <main>
    Du har klikket på et link, der ikke fører nogen steder hen eller du har indtastet en ikke eksisterende adresse i
    browserens adressefelt.
  </main>

  <footer>
    <footer-content></footer-content>
  </footer>
</page-layout>
