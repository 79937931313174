import * as i0 from '@angular/core';
import { inject, Injectable, Inject, Optional, EventEmitter, Component, Output, SkipSelf } from '@angular/core';
import * as i3 from '@angular/forms';
import { FormControl, Validators, ReactiveFormsModule } from '@angular/forms';
import * as i5 from '@angular/material/button';
import { MatIconButton, MatButtonModule } from '@angular/material/button';
import * as i4 from '@angular/material/dialog';
import { MatDialogModule } from '@angular/material/dialog';
import * as i6 from '@angular/material/divider';
import { MatDividerModule } from '@angular/material/divider';
import * as i7 from '@angular/material/form-field';
import { MatFormFieldModule } from '@angular/material/form-field';
import * as i8 from '@angular/material/input';
import { MatInputModule } from '@angular/material/input';
import { BehaviorSubject, ReplaySubject, AsyncSubject, isObservable, Subject } from 'rxjs';
import { skip, filter, take, takeUntil, map } from 'rxjs/operators';
import * as i2 from '@public/core';
import { UserService, SingletonError } from '@public/core';
import { OAuth2Service } from '@public/core/authorization';
const _c0 = "[_nghost-%COMP%]{display:inline-block}button[_ngcontent-%COMP%]{display:block;margin:auto}";
function LoginDialogComponent_facebook_login_button_4_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "facebook-login-button");
  }
}
function LoginDialogComponent_span_6_Template(rf, ctx) {
  if (rf & 1) {
    const _r1 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "span")(1, "button", 14);
    i0.ɵɵlistener("click", function LoginDialogComponent_span_6_Template_button_click_1_listener() {
      i0.ɵɵrestoreView(_r1);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.loginWithApple());
    });
    i0.ɵɵelement(2, "i", 15);
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(3, "div", 16);
    i0.ɵɵtext(4, "Apple");
    i0.ɵɵelementEnd()();
  }
}
function LoginDialogComponent_span_7_Template(rf, ctx) {
  if (rf & 1) {
    const _r3 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "span")(1, "button", 14);
    i0.ɵɵlistener("click", function LoginDialogComponent_span_7_Template_button_click_1_listener() {
      i0.ɵɵrestoreView(_r3);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.loginWithLinkedIn());
    });
    i0.ɵɵelement(2, "i", 17);
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(3, "div", 16);
    i0.ɵɵtext(4, "LinkedIn");
    i0.ɵɵelementEnd()();
  }
}
class BaseAuthenticationProvider {
  name;
  constructor(name) {
    this.name = name;
  }
  loadScript(src, onload, async = true) {
    if (typeof document !== 'undefined' && !document.getElementById(this.name)) {
      const signInJS = document.createElement('script');
      signInJS.async = async;
      signInJS.src = src;
      signInJS.onload = onload;
      document.head.appendChild(signInJS);
    }
  }
}
const defaultConfig$3 = {
  clientId: 'api',
  clientSecret: 'secret'
};
class NativeAuthenticationProvider extends BaseAuthenticationProvider {
  static PROVIDER_ID = 'Native';
  userService = inject(UserService);
  oauth2Service = inject(OAuth2Service);
  config;
  constructor(config) {
    super(NativeAuthenticationProvider.PROVIDER_ID);
    this.config = {
      ...defaultConfig$3,
      ...config
    };
  }
  initialize() {
    return new Promise((resolve, reject) => {
      try {
        resolve();
      } catch (error) {
        reject(error);
      }
    });
  }
  getStatus() {
    return new Promise((resolve, reject) => {
      const currentUser = this.userService.getCurrentUser();
      if (currentUser) {
        resolve(currentUser);
      } else {
        reject('Currently not logged in');
      }
    });
  }
  login(loginOptions) {
    const options = {
      ...this.config,
      ...loginOptions
    };
    return new Promise((resolve, reject) => {
      this.oauth2Service.login(options.username, options.password).subscribe({
        next: response => {
          resolve({
            provider: NativeAuthenticationProvider.PROVIDER_ID,
            id: response.access_token,
            username: options.username
          });
        },
        error: reject
      });
    });
  }
  logout() {
    return new Promise((resolve, reject) => {
      this.oauth2Service.logout().subscribe({
        next: resolve,
        error: reject
      });
    });
  }
  static ɵfac = function NativeAuthenticationProvider_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || NativeAuthenticationProvider)(i0.ɵɵinject('NativeAuthenticationConfig', 8));
  };
  static ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
    token: NativeAuthenticationProvider,
    factory: NativeAuthenticationProvider.ɵfac,
    providedIn: 'root'
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NativeAuthenticationProvider, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: undefined,
    decorators: [{
      type: Inject,
      args: ['NativeAuthenticationConfig']
    }, {
      type: Optional
    }]
  }], null);
})();
const defaultConfig$2 = {
  version: 'v17.0',
  scope: 'email,public_profile',
  locale: 'en_US',
  fields: 'name,email,picture'
};
class FacebookAuthenticationProvider extends BaseAuthenticationProvider {
  static PROVIDER_ID = 'Facebook';
  config;
  constructor(config) {
    super(FacebookAuthenticationProvider.PROVIDER_ID);
    this.config = {
      ...defaultConfig$2,
      ...config
    };
  }
  initialize() {
    return new Promise((resolve, reject) => {
      try {
        this.loadScript(`//connect.facebook.net/${this.config.locale}/sdk.js`, () => {
          FB.init({
            appId: this.config.clientId,
            autoLogAppEvents: true,
            cookie: true,
            xfbml: false,
            version: this.config.version
          });
          resolve();
        });
      } catch (error) {
        reject(error);
      }
    });
  }
  getStatus() {
    return new Promise((resolve, reject) => {
      FB.getLoginStatus(response => {
        if (response.status === 'connected') {
          FB.api(`/me?fields=${this.config.fields}`, payload => {
            resolve(this.createUser(payload));
          });
        } else {
          reject(`No user is currently logged in with ${FacebookAuthenticationProvider.PROVIDER_ID}`);
        }
      });
    });
  }
  login(loginOptions) {
    const options = {
      ...this.config,
      ...loginOptions
    };
    return new Promise((resolve, reject) => {
      FB.login(response => {
        if (response.authResponse) {
          FB.api(`/me?fields=${options.fields}`, payload => {
            resolve(this.createUser(payload));
          });
        } else {
          reject('User cancelled login or did not fully authorize.');
        }
      }, options);
    });
  }
  logout() {
    return new Promise(resolve => {
      FB.logout(() => {
        resolve();
      });
    });
  }
  createUser(payload) {
    const user = {
      provider: FacebookAuthenticationProvider.PROVIDER_ID,
      id: payload.id
    };
    user.realname = payload.name;
    user.email = payload.email;
    //user.photoUrl = payload.picture.data.url;
    user.photoUrl = `https://graph.facebook.com/${payload.id}/picture?type=normal`;
    return user;
  }
  static ɵfac = function FacebookAuthenticationProvider_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || FacebookAuthenticationProvider)(i0.ɵɵinject('FacebookAuthenticationConfig'));
  };
  static ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
    token: FacebookAuthenticationProvider,
    factory: FacebookAuthenticationProvider.ɵfac,
    providedIn: 'root'
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(FacebookAuthenticationProvider, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: undefined,
    decorators: [{
      type: Inject,
      args: ['FacebookAuthenticationConfig']
    }]
  }], null);
})();
const defaultConfig$1 = {
  oneTapEnabled: true
};
class GoogleAuthenticationProvider extends BaseAuthenticationProvider {
  static PROVIDER_ID = 'Google';
  userChange = new EventEmitter();
  user = new BehaviorSubject(undefined);
  _accessToken = new BehaviorSubject(undefined);
  _receivedAccessToken = new EventEmitter();
  _tokenClient;
  config;
  constructor(config) {
    super(GoogleAuthenticationProvider.PROVIDER_ID);
    this.config = {
      ...defaultConfig$1,
      ...config
    };
    // emit changeUser events but skip initial value from behaviorSubject
    this.user.pipe(skip(1)).subscribe(this.userChange);
    // emit receivedAccessToken but skip initial value from behaviorSubject
    this._accessToken.pipe(skip(1)).subscribe(this._receivedAccessToken);
  }
  initialize(autoLogin) {
    return new Promise((resolve, reject) => {
      try {
        this.loadScript('https://accounts.google.com/gsi/client', () => {
          google.accounts.id.initialize({
            client_id: this.config.clientId,
            auto_select: autoLogin,
            callback: ({
              credential
            }) => {
              const user = this.createUser(credential);
              this.user.next(user);
            },
            prompt_parent_id: this.config?.prompt_parent_id,
            itp_support: this.config?.oneTapEnabled
          });
          if (this.config?.oneTapEnabled) {
            this.user.pipe(filter(user => user === undefined)).subscribe(() => google.accounts.id.prompt(console.debug));
          }
          if (this.config?.scopes) {
            const scope = this.config.scopes instanceof Array ? this.config.scopes.filter(s => s).join(' ') : this.config.scopes;
            this._tokenClient = google.accounts.oauth2.initTokenClient({
              client_id: this.config.clientId,
              scope,
              prompt: this.config.prompt,
              callback: tokenResponse => {
                if (tokenResponse.error) {
                  this._accessToken.error({
                    code: tokenResponse.error,
                    description: tokenResponse.error_description,
                    uri: tokenResponse.error_uri
                  });
                } else {
                  this._accessToken.next(tokenResponse.access_token);
                }
              }
            });
          }
          resolve();
        });
      } catch (err) {
        reject(err);
      }
    });
  }
  getStatus() {
    return new Promise((resolve, reject) => {
      if (this.user.value) {
        resolve(this.user.value);
      } else {
        reject(`No user is currently authenticated with ${this.name}`);
      }
    });
  }
  refreshToken() {
    return new Promise((resolve, reject) => {
      google.accounts.id.revoke(this.user.value?.id, response => {
        if (response.error) {
          reject(response.error);
        } else {
          resolve(this.user.value);
        }
      });
    });
  }
  getAccessToken() {
    return new Promise((resolve, reject) => {
      if (!this._tokenClient) {
        if (this.user.value) {
          reject('No token client was instantiated, you should specify some scopes.');
        } else {
          reject('You should be authenticated first.');
        }
      } else {
        this._tokenClient.requestAccessToken({
          hint: this.user.value?.email
        });
        this._receivedAccessToken.pipe(take(1)).subscribe(resolve);
      }
    });
  }
  revokeAccessToken() {
    return new Promise((resolve, reject) => {
      if (!this._tokenClient) {
        reject('No token client was instantiated, you should specify some scopes.');
      } else if (!this._accessToken.value) {
        reject('No access token to revoke');
      } else {
        google.accounts.oauth2.revoke(this._accessToken.value, () => {
          this._accessToken.next(undefined);
          resolve();
        });
      }
    });
  }
  login() {
    return Promise.reject('You should not call this method directly for Google, use button with "google-login-button" ' + 'or generate the button yourself with "google.accounts.id.renderButton()" ' + '(https://developers.google.com/identity/gsi/web/guides/display-button#javascript)');
  }
  async logout() {
    google.accounts.id.disableAutoSelect();
    this.user.next(undefined);
  }
  createUser(idToken) {
    const payload = this.decodeJwt(idToken);
    const user = {
      provider: GoogleAuthenticationProvider.PROVIDER_ID,
      id: payload['sub']
    };
    user.realname = payload['name'];
    user.email = payload['email'];
    user.photoUrl = payload['picture'];
    return user;
  }
  decodeJwt(idToken) {
    const base64Url = idToken.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
    return JSON.parse(jsonPayload);
  }
  static ɵfac = function GoogleAuthenticationProvider_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || GoogleAuthenticationProvider)(i0.ɵɵinject('GoogleAuthenticationConfig'));
  };
  static ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
    token: GoogleAuthenticationProvider,
    factory: GoogleAuthenticationProvider.ɵfac,
    providedIn: 'root'
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(GoogleAuthenticationProvider, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: undefined,
    decorators: [{
      type: Inject,
      args: ['GoogleAuthenticationConfig']
    }]
  }], null);
})();
class GoogleLoginButtonComponent {
  loginWithGoogle = new EventEmitter();
  login() {
    this.loginWithGoogle.emit(this.createFakeGoogleWrapper());
  }
  createFakeGoogleWrapper = () => {
    const googleLoginWrapper = document.createElement('div');
    googleLoginWrapper.style.display = 'none';
    googleLoginWrapper.classList.add('custom-google-button');
    document.body.appendChild(googleLoginWrapper);
    window.google.accounts.id.renderButton(googleLoginWrapper, {
      type: 'icon',
      width: '200'
    });
    const googleLoginWrapperButton = googleLoginWrapper.querySelector('div[role=button]');
    return {
      click: () => {
        googleLoginWrapperButton?.click();
      }
    };
  };
  static ɵfac = function GoogleLoginButtonComponent_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || GoogleLoginButtonComponent)();
  };
  static ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
    type: GoogleLoginButtonComponent,
    selectors: [["google-login-button"]],
    outputs: {
      loginWithGoogle: "loginWithGoogle"
    },
    decls: 4,
    vars: 0,
    consts: [["mat-icon-button", "", "color", "primary", 3, "click"], [1, "fa", "fab", "fa-brands", "fa-google"], [2, "margin-top", "0.5rem"]],
    template: function GoogleLoginButtonComponent_Template(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵelementStart(0, "button", 0);
        i0.ɵɵlistener("click", function GoogleLoginButtonComponent_Template_button_click_0_listener() {
          return ctx.login();
        });
        i0.ɵɵelement(1, "i", 1);
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(2, "div", 2);
        i0.ɵɵtext(3, "Google");
        i0.ɵɵelementEnd();
      }
    },
    dependencies: [MatIconButton],
    styles: ["[_nghost-%COMP%]{display:inline-block}button[_ngcontent-%COMP%]{display:block;margin:auto}"]
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(GoogleLoginButtonComponent, [{
    type: Component,
    args: [{
      selector: 'google-login-button',
      imports: [MatIconButton],
      template: "<button mat-icon-button color=\"primary\" (click)=\"login()\">\r\n  <i class=\"fa fab fa-brands fa-google\"></i>\r\n</button>\r\n<div style=\"margin-top: 0.5rem\">Google</div>",
      styles: [":host{display:inline-block}button{display:block;margin:auto}\n"]
    }]
  }], null, {
    loginWithGoogle: [{
      type: Output
    }]
  });
})();
class AuthenticationService {
  userService;
  ngZone;
  injector;
  static ERR_PROVIDERS_NOT_INITIALIZED = 'Authentication providers not initialized. Are there errors on your console?';
  static ERR_PROVIDER_NOT_CONFIGURED = 'Authentication provider not configured';
  static ERR_USER_NOT_AUTHENTICATED = 'Not authenticated';
  static ERR_PROVIDER_DOES_NOT_SUPPORT_ACCESS_TOKEN = 'Chosen authentication provider does not support getting an access token';
  static ERR_PROVIDER_DOES_NOT_SUPPORT_REFRESH_TOKEN = 'Chosen authentication provider does not support refreshing a token';
  providers = new Map();
  initialized = false;
  currentUser;
  autoLogin = false;
  authenticationStateSource = new ReplaySubject(1);
  initializationStateSource = new AsyncSubject();
  /**
   * An `Observable` that one can subscribe to get the current logged in user information.
   */
  get authenticationState() {
    return this.authenticationStateSource.asObservable();
  }
  /**
   * An `Observable` to communicate the readiness of the service and associated authentication providers.
   */
  get initializationState() {
    return this.initializationStateSource.asObservable();
  }
  /**
   * @param config An `AuthenticationConfig` object or a `Promise` that resolves to an `AnthenticationConfig` object.
   */
  constructor(config, userService, ngZone, injector) {
    this.userService = userService;
    this.ngZone = ngZone;
    this.injector = injector;
    if (config instanceof Promise) {
      config.then(config => {
        this.initialize(config);
      });
    } else {
      this.initialize(config);
    }
  }
  /**
   * A method used to authenticate a user with a specific `AuthenticationProvider`.
   *
   * @param provider Name with which the `AuthenticationProvider` has been registered with the service
   * @param options Optional `AuthenticationProvider` specific arguments
   * @returns A `Promise` that resolves to the authenticated user
   */
  login(provider, options) {
    return new Promise((resolve, reject) => {
      if (!this.initialized) {
        reject(AuthenticationService.ERR_PROVIDERS_NOT_INITIALIZED);
      } else if (!this.providers.get(provider)) {
        reject(AuthenticationService.ERR_PROVIDER_NOT_CONFIGURED);
      } else {
        this.providers.get(provider)?.login(options).then(user => {
          this.setUser(user, provider);
          resolve(user);
        }).catch(err => {
          reject(err);
        });
      }
    });
  }
  /**
   * A method used to logout the currently authenticated user.
   *
   * @param revoke Optional parameter to specify whether a "hard" logout is to be performed
   * @returns A `Promise` that resolves if the operation is successful, rejects otherwise
   */
  logout(revoke = false) {
    return new Promise((resolve, reject) => {
      if (!this.initialized) {
        reject(AuthenticationService.ERR_PROVIDERS_NOT_INITIALIZED);
      } else if (!this.currentUser) {
        reject(AuthenticationService.ERR_USER_NOT_AUTHENTICATED);
      } else if (!this.providers.get(this.currentUser.provider)) {
        reject(AuthenticationService.ERR_PROVIDER_NOT_CONFIGURED);
      } else {
        this.providers.get(this.currentUser.provider)?.logout(revoke).then(() => {
          resolve();
          this.setUser(undefined);
        }).catch(error => {
          reject(error);
        });
      }
    });
  }
  /**
   *
   * @param config  An `AuthenticationConfig` object.
   */
  initialize(config) {
    this.autoLogin = config.autoLogin !== undefined ? config.autoLogin : false;
    const {
      onError = console.error
    } = config;
    config.providers.forEach(provider => {
      provider = 'prototype' in provider ? this.injector.get(provider) : provider;
      this.providers.set(provider.name, provider);
    });
    Promise.all(Array.from(this.providers.values()).map(provider => provider.initialize(this.autoLogin))).then(() => {
      if (this.autoLogin) {
        const loginStatusPromises = [];
        let authenticated = false;
        this.providers.forEach(provider => {
          const promise = provider.getStatus();
          loginStatusPromises.push(promise);
          promise.then(user => {
            this.setUser(user, provider.name);
            authenticated = true;
          }).catch(console.debug);
        });
        Promise.all(loginStatusPromises).catch(() => {
          if (!authenticated) {
            this.currentUser = undefined;
            this.authenticationStateSource.next(undefined);
          }
        });
      }
      this.providers.forEach(provider => {
        if (isObservable(provider.userChange)) {
          provider.userChange.subscribe(user => {
            this.ngZone.run(() => {
              this.setUser(user, provider.name);
            });
          });
        }
      });
    }).catch(error => {
      onError(error);
    }).finally(() => {
      this.initialized = true;
      this.initializationStateSource.next(this.initialized);
      this.initializationStateSource.complete();
    });
  }
  /**
   *
   * @param user
   * @param provider
   */
  setUser(user, provider) {
    this.currentUser = user;
    if (user && provider) {
      user.provider = provider;
    }
    this.userService.setCurrentUser(user);
    this.authenticationStateSource.next(user);
  }
  static ɵfac = function AuthenticationService_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || AuthenticationService)(i0.ɵɵinject('AuthenticationConfig'), i0.ɵɵinject(i2.UserService), i0.ɵɵinject(i0.NgZone), i0.ɵɵinject(i0.Injector));
  };
  static ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
    token: AuthenticationService,
    factory: AuthenticationService.ɵfac,
    providedIn: 'root'
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AuthenticationService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: undefined,
    decorators: [{
      type: Inject,
      args: ['AuthenticationConfig']
    }]
  }, {
    type: i2.UserService
  }, {
    type: i0.NgZone
  }, {
    type: i0.Injector
  }], null);
})();
class FacebookLoginButtonComponent {
  authenticationSrvice;
  constructor(authenticationSrvice) {
    this.authenticationSrvice = authenticationSrvice;
  }
  login() {
    this.authenticationSrvice.login(FacebookAuthenticationProvider.PROVIDER_ID);
  }
  static ɵfac = function FacebookLoginButtonComponent_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || FacebookLoginButtonComponent)(i0.ɵɵdirectiveInject(AuthenticationService));
  };
  static ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
    type: FacebookLoginButtonComponent,
    selectors: [["facebook-login-button"]],
    decls: 4,
    vars: 0,
    consts: [["mat-icon-button", "", "color", "primary", 3, "click"], [1, "fa", "fab", "fa-brands", "fa-facebook"], [2, "margin-top", "0.5rem"]],
    template: function FacebookLoginButtonComponent_Template(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵelementStart(0, "button", 0);
        i0.ɵɵlistener("click", function FacebookLoginButtonComponent_Template_button_click_0_listener() {
          return ctx.login();
        });
        i0.ɵɵelement(1, "i", 1);
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(2, "div", 2);
        i0.ɵɵtext(3, "Facebook");
        i0.ɵɵelementEnd();
      }
    },
    dependencies: [MatIconButton],
    styles: [_c0]
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(FacebookLoginButtonComponent, [{
    type: Component,
    args: [{
      selector: 'facebook-login-button',
      imports: [MatIconButton],
      template: "<button mat-icon-button color=\"primary\" (click)=\"login()\">\r\n  <i class=\"fa fab fa-brands fa-facebook\"></i>\r\n</button>\r\n<div style=\"margin-top: 0.5rem\">Facebook</div>\r\n",
      styles: [":host{display:inline-block}button{display:block;margin:auto}\n"]
    }]
  }], () => [{
    type: AuthenticationService
  }], null);
})();

// Import Angular stuff
class LoginDialogConfig {
  labels;
  messages;
}
const defaultConfig = {
  labels: {
    title: 'Login using',
    username: 'Username',
    password: 'Password',
    alternative: 'or',
    login: 'Login',
    cancel: 'Cancel'
  },
  messages: {
    loginSuccess: name => `You have been logged in as ${name}`,
    loginFailure: 'An error occurred while logging in',
    badCredentials: 'Login failed, due to wrong username or password'
  }
};
class LoginDialogComponent {
  authenticationService;
  alertService;
  builder;
  dialog;
  form;
  username = new FormControl('', Validators.required);
  password = new FormControl('', Validators.required);
  facebook = true;
  google = true;
  apple = false;
  linkedIn = false;
  message;
  config;
  unsubscriber = new Subject();
  constructor(authenticationService, alertService, builder, dialog, config) {
    this.authenticationService = authenticationService;
    this.alertService = alertService;
    this.builder = builder;
    this.dialog = dialog;
    this.config = {
      labels: {
        ...defaultConfig.labels,
        ...config?.labels
      },
      messages: {
        ...defaultConfig.messages,
        ...config?.messages
      }
    };
  }
  ngOnInit() {
    this.form = this.builder.group({
      username: this.username,
      password: this.password
    });
    this.authenticationService.authenticationState.pipe(takeUntil(this.unsubscriber)).subscribe({
      next: user => {
        if (user) {
          this.onLoginSuccess(user);
        }
      }
    });
  }
  ngOnDestroy() {
    this.unsubscriber.next();
    this.unsubscriber.complete();
  }
  login() {
    this.authenticationService.login(NativeAuthenticationProvider.PROVIDER_ID, {
      username: this.username.value,
      password: this.password.value
    }).then(user => {
      this.onLoginSuccess(user);
    }).catch(error => {
      this.onLoginFailure(error);
    });
    this.message = undefined;
  }
  cancel() {
    this.dialog.close();
  }
  loginWithFacebook() {
    this.authenticationService.login(FacebookAuthenticationProvider.PROVIDER_ID).then(user => {
      this.onLoginSuccess(user);
    }).catch(error => {
      this.onLoginFailure(error);
    });
  }
  loginWithGoogle(googleWrapper) {
    googleWrapper.click();
  }
  loginWithApple() {
    this.dialog.close();
    this.alertService.warning('Login with Apple has not been implemented');
  }
  loginWithLinkedIn() {
    this.dialog.close();
    this.alertService.warning('Login with LinkedIn has not been implemented');
  }
  onLoginSuccess = user => {
    this.dialog.close();
    const message = typeof this.config.messages?.loginSuccess === 'function' ? this.config.messages.loginSuccess(user.realname ? user.realname : user.username ? user.username : 'unknown') : this.config.messages?.loginSuccess;
    if (message && typeof message === 'string') {
      this.alertService.success(message, 3000);
    }
  };
  onLoginFailure = error => {
    if (error.status === 400) {
      this.message = this.config.messages?.badCredentials;
    } else {
      this.dialog.close();
      if (this.config.messages?.loginFailure) {
        this.alertService.error(this.config.messages.loginFailure);
      }
    }
  };
  static ɵfac = function LoginDialogComponent_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || LoginDialogComponent)(i0.ɵɵdirectiveInject(AuthenticationService), i0.ɵɵdirectiveInject(i2.AlertService), i0.ɵɵdirectiveInject(i3.FormBuilder), i0.ɵɵdirectiveInject(i4.MatDialogRef), i0.ɵɵdirectiveInject(LoginDialogConfig, 8));
  };
  static ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
    type: LoginDialogComponent,
    selectors: [["ng-component"]],
    decls: 29,
    vars: 12,
    consts: [["mat-dialog-title", ""], ["mat-dialog-content", ""], [1, "providers"], [4, "ngIf"], [3, "loginWithGoogle"], [1, "divider"], [3, "submit", "formGroup"], [1, "full-width"], ["matInput", "", "type", "text", "formControlName", "username", "cdkFocusInitial", ""], ["matInput", "", "type", "password", "formControlName", "password"], [1, "message"], ["mat-dialog-actions", ""], ["mat-raised-button", "", "type", "submit", "color", "primary", 3, "click", "disabled"], ["mat-raised-button", "", "type", "button ", 3, "click"], ["mat-icon-button", "", "color", "primary", 3, "click"], [1, "fab", "fa-brands", "fa-apple"], [2, "margin-top", "0.5rem"], [1, "fab", "fa-brands", "fa-linkedin"]],
    template: function LoginDialogComponent_Template(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵelementStart(0, "h1", 0);
        i0.ɵɵtext(1);
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(2, "div", 1)(3, "div", 2);
        i0.ɵɵtemplate(4, LoginDialogComponent_facebook_login_button_4_Template, 1, 0, "facebook-login-button", 3);
        i0.ɵɵelementStart(5, "google-login-button", 4);
        i0.ɵɵlistener("loginWithGoogle", function LoginDialogComponent_Template_google_login_button_loginWithGoogle_5_listener($event) {
          return ctx.loginWithGoogle($event);
        });
        i0.ɵɵelementEnd();
        i0.ɵɵtemplate(6, LoginDialogComponent_span_6_Template, 5, 0, "span", 3)(7, LoginDialogComponent_span_7_Template, 5, 0, "span", 3);
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(8, "div", 5);
        i0.ɵɵelement(9, "mat-divider");
        i0.ɵɵelementStart(10, "span");
        i0.ɵɵtext(11);
        i0.ɵɵelementEnd();
        i0.ɵɵelement(12, "mat-divider");
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(13, "form", 6);
        i0.ɵɵlistener("submit", function LoginDialogComponent_Template_form_submit_13_listener() {
          return ctx.login();
        });
        i0.ɵɵelementStart(14, "mat-form-field", 7)(15, "mat-label");
        i0.ɵɵtext(16);
        i0.ɵɵelementEnd();
        i0.ɵɵelement(17, "input", 8);
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(18, "mat-form-field", 7)(19, "mat-label");
        i0.ɵɵtext(20);
        i0.ɵɵelementEnd();
        i0.ɵɵelement(21, "input", 9);
        i0.ɵɵelementEnd()();
        i0.ɵɵelementStart(22, "div", 10);
        i0.ɵɵtext(23);
        i0.ɵɵelementEnd()();
        i0.ɵɵelementStart(24, "div", 11)(25, "button", 12);
        i0.ɵɵlistener("click", function LoginDialogComponent_Template_button_click_25_listener() {
          return ctx.login();
        });
        i0.ɵɵtext(26);
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(27, "button", 13);
        i0.ɵɵlistener("click", function LoginDialogComponent_Template_button_click_27_listener() {
          return ctx.cancel();
        });
        i0.ɵɵtext(28);
        i0.ɵɵelementEnd()();
      }
      if (rf & 2) {
        i0.ɵɵadvance();
        i0.ɵɵtextInterpolate(ctx.config.labels == null ? null : ctx.config.labels.title);
        i0.ɵɵadvance(3);
        i0.ɵɵproperty("ngIf", ctx.facebook);
        i0.ɵɵadvance(2);
        i0.ɵɵproperty("ngIf", ctx.apple);
        i0.ɵɵadvance();
        i0.ɵɵproperty("ngIf", ctx.linkedIn);
        i0.ɵɵadvance(4);
        i0.ɵɵtextInterpolate(ctx.config.labels == null ? null : ctx.config.labels.alternative);
        i0.ɵɵadvance(2);
        i0.ɵɵproperty("formGroup", ctx.form);
        i0.ɵɵadvance(3);
        i0.ɵɵtextInterpolate(ctx.config.labels == null ? null : ctx.config.labels.username);
        i0.ɵɵadvance(4);
        i0.ɵɵtextInterpolate(ctx.config.labels == null ? null : ctx.config.labels.password);
        i0.ɵɵadvance(3);
        i0.ɵɵtextInterpolate(ctx.message);
        i0.ɵɵadvance(2);
        i0.ɵɵproperty("disabled", ctx.form.invalid);
        i0.ɵɵadvance();
        i0.ɵɵtextInterpolate1(" ", ctx.config.labels == null ? null : ctx.config.labels.login, " ");
        i0.ɵɵadvance(2);
        i0.ɵɵtextInterpolate(ctx.config.labels == null ? null : ctx.config.labels.cancel);
      }
    },
    dependencies: [ReactiveFormsModule, i3.ɵNgNoValidate, i3.DefaultValueAccessor, i3.NgControlStatus, i3.NgControlStatusGroup, i3.FormGroupDirective, i3.FormControlName, MatButtonModule, i5.MatButton, i5.MatIconButton, MatDialogModule, i4.MatDialogTitle, i4.MatDialogActions, i4.MatDialogContent, MatDividerModule, i6.MatDivider, MatFormFieldModule, i7.MatFormField, i7.MatLabel, MatInputModule, i8.MatInput, FacebookLoginButtonComponent, GoogleLoginButtonComponent],
    styles: [".providers[_ngcontent-%COMP%]{display:flex;flex-direction:row;justify-content:space-evenly}.divider[_ngcontent-%COMP%]{margin-block:3rem;margin-inline:1rem;display:flex;align-items:center}.divider[_ngcontent-%COMP%] > span[_ngcontent-%COMP%]{display:inline-block;text-align:center;width:10%}.divider[_ngcontent-%COMP%] > mat-divider[_ngcontent-%COMP%]:first-child, .divider[_ngcontent-%COMP%] > mat-divider[_ngcontent-%COMP%]:last-child{width:45%}.full-width[_ngcontent-%COMP%]{width:100%}.message[_ngcontent-%COMP%]{height:1rem;color:red}"]
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(LoginDialogComponent, [{
    type: Component,
    args: [{
      imports: [ReactiveFormsModule, MatButtonModule, MatDialogModule, MatDividerModule, MatFormFieldModule, MatInputModule, FacebookLoginButtonComponent, GoogleLoginButtonComponent],
      template: "<h1 mat-dialog-title>{{ config.labels?.title }}</h1>\r\n\r\n<div mat-dialog-content>\r\n  <div class=\"providers\">\r\n    <facebook-login-button *ngIf=\"facebook\"></facebook-login-button>\r\n\r\n    <google-login-button (loginWithGoogle)=\"loginWithGoogle($event)\"></google-login-button>\r\n    \r\n    <span *ngIf=\"apple\">\r\n      <button mat-icon-button color=\"primary\" (click)=\"loginWithApple()\">\r\n        <i class=\"fab fa-brands fa-apple\"></i>\r\n      </button>\r\n      <div style=\"margin-top: 0.5rem\">Apple</div>\r\n    </span>\r\n\r\n    <span *ngIf=\"linkedIn\">\r\n      <button mat-icon-button color=\"primary\" (click)=\"loginWithLinkedIn()\">\r\n        <i class=\"fab fa-brands fa-linkedin\"></i>\r\n      </button>\r\n      <div style=\"margin-top: 0.5rem\">LinkedIn</div>\r\n    </span>\r\n  </div>\r\n\r\n  <div class=\"divider\">\r\n    <mat-divider></mat-divider>\r\n    <span>{{ config.labels?.alternative }}</span>\r\n    <mat-divider></mat-divider>\r\n  </div>\r\n\r\n  <form [formGroup]=\"form\" (submit)=\"login()\">\r\n    <mat-form-field class=\"full-width\">\r\n      <mat-label>{{ config.labels?.username }}</mat-label>\r\n      <input matInput type=\"text\" formControlName=\"username\" cdkFocusInitial />\r\n    </mat-form-field>\r\n    <mat-form-field class=\"full-width\">\r\n      <mat-label>{{ config.labels?.password }}</mat-label>\r\n      <input matInput type=\"password\" formControlName=\"password\" />\r\n    </mat-form-field>\r\n  </form>\r\n\r\n  <div class=\"message\">{{ this.message }}</div>\r\n</div>\r\n\r\n<div mat-dialog-actions>\r\n  <button mat-raised-button type=\"submit\" color=\"primary\" [disabled]=\"form.invalid\" (click)=\"login()\">\r\n    {{ config.labels?.login }}\r\n  </button>\r\n  <button mat-raised-button type=\"button \" (click)=\"cancel()\">{{ config.labels?.cancel }}</button>\r\n</div>\r\n",
      styles: [".providers{display:flex;flex-direction:row;justify-content:space-evenly}.divider{margin-block:3rem;margin-inline:1rem;display:flex;align-items:center}.divider>span{display:inline-block;text-align:center;width:10%}.divider>mat-divider:first-child,.divider>mat-divider:last-child{width:45%}.full-width{width:100%}.message{height:1rem;color:red}\n"]
    }]
  }], () => [{
    type: AuthenticationService
  }, {
    type: i2.AlertService
  }, {
    type: i3.FormBuilder
  }, {
    type: i4.MatDialogRef
  }, {
    type: LoginDialogConfig,
    decorators: [{
      type: Optional
    }]
  }], null);
})();
class AuthenticationGuard {
  dialog;
  userService;
  instance;
  constructor(dialog, userService, instance) {
    this.dialog = dialog;
    this.userService = userService;
    this.instance = instance;
    if (instance) {
      throw new SingletonError(this);
    }
  }
  canActivate() {
    return this.ensureAuthentication();
  }
  canLoad() {
    return this.ensureAuthentication();
  }
  ensureAuthentication() {
    if (!this.userService.getCurrentUser()) {
      this.dialog.open(LoginDialogComponent, {
        disableClose: true
      });
      return this.userService.userChange.pipe(map(user => !!user));
    }
    return true;
  }
  static ɵfac = function AuthenticationGuard_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || AuthenticationGuard)(i0.ɵɵinject(i4.MatDialog), i0.ɵɵinject(i2.UserService), i0.ɵɵinject(AuthenticationGuard, 12));
  };
  static ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
    token: AuthenticationGuard,
    factory: AuthenticationGuard.ɵfac,
    providedIn: 'root'
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AuthenticationGuard, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: i4.MatDialog
  }, {
    type: i2.UserService
  }, {
    type: AuthenticationGuard,
    decorators: [{
      type: Optional
    }, {
      type: SkipSelf
    }]
  }], null);
})();

/**
 * Public surface API of @public/core/authentication
 */

/**
 * Generated bundle index. Do not edit.
 */

export { AuthenticationGuard, AuthenticationService, BaseAuthenticationProvider, FacebookAuthenticationProvider, FacebookLoginButtonComponent, GoogleAuthenticationProvider, GoogleLoginButtonComponent, LoginDialogComponent, LoginDialogConfig, NativeAuthenticationProvider };
