import * as i0 from '@angular/core';
import { Injectable, Optional, SkipSelf, Directive, Component, ViewChild } from '@angular/core';
import { BehaviorSubject, ReplaySubject } from 'rxjs';
import * as i4 from '@angular/common';
import { CommonModule } from '@angular/common';
import * as i5 from '@angular/material/button';
import { MatButtonModule } from '@angular/material/button';
import * as i6 from '@angular/material/icon';
import { MatIconModule } from '@angular/material/icon';
import { takeUntil } from 'rxjs/operators';
import * as i1 from '@angular/platform-browser';
import * as i3 from '@public/core/ui/sidenav';
function ToolbarComponent_button_0_Template(rf, ctx) {
  if (rf & 1) {
    const _r1 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "button", 2);
    i0.ɵɵlistener("click", function ToolbarComponent_button_0_Template_button_click_0_listener() {
      i0.ɵɵrestoreView(_r1);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.sidenav.toggle());
    });
    i0.ɵɵelementStart(1, "mat-icon");
    i0.ɵɵtext(2, "menu");
    i0.ɵɵelementEnd()();
  }
}
function ToolbarComponent_ng_template_1_Template(rf, ctx) {}
class Toolbar {
  instance;
  contentChangeSource = new BehaviorSubject(undefined);
  get content() {
    return this.contentChangeSource.value;
  }
  set content(component) {
    this.contentChangeSource.next(component);
  }
  get contentChange() {
    return this.contentChangeSource.asObservable();
  }
  constructor(instance) {
    this.instance = instance;
    if (instance) {
      throw new Error(`${this.constructor.name} already instantiated. Provide it only in the root module.`);
    }
  }
  static ɵfac = function Toolbar_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || Toolbar)(i0.ɵɵinject(Toolbar, 12));
  };
  static ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
    token: Toolbar,
    factory: Toolbar.ɵfac,
    providedIn: 'root'
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(Toolbar, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: Toolbar,
    decorators: [{
      type: Optional
    }, {
      type: SkipSelf
    }]
  }], null);
})();
class ToolbarContentDirective {
  viewContainerRef;
  constructor(viewContainerRef) {
    this.viewContainerRef = viewContainerRef;
  }
  static ɵfac = function ToolbarContentDirective_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || ToolbarContentDirective)(i0.ɵɵdirectiveInject(i0.ViewContainerRef));
  };
  static ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
    type: ToolbarContentDirective,
    selectors: [["", "toolbar-content", ""]]
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ToolbarContentDirective, [{
    type: Directive,
    args: [{
      selector: '[toolbar-content]'
    }]
  }], () => [{
    type: i0.ViewContainerRef
  }], null);
})();
class ToolbarComponent {
  titleService;
  toolbar;
  sidenav;
  content;
  destroyer = new ReplaySubject(1);
  constructor(titleService, toolbar, sidenav) {
    this.titleService = titleService;
    this.toolbar = toolbar;
    this.sidenav = sidenav;
  }
  /**
   *
   */
  ngOnInit() {
    this.toolbar.contentChange.pipe(takeUntil(this.destroyer)).subscribe(component => {
      this.loadContent(component);
    });
  }
  /**
   *
   */
  ngOnDestroy() {
    this.destroyer.next(true);
    this.destroyer.complete();
  }
  loadContent(component) {
    if (this.content) {
      this.content.viewContainerRef.clear();
      if (component) {
        this.content.viewContainerRef.createComponent(component);
      }
    }
  }
  static ɵfac = function ToolbarComponent_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || ToolbarComponent)(i0.ɵɵdirectiveInject(i1.Title), i0.ɵɵdirectiveInject(Toolbar), i0.ɵɵdirectiveInject(i3.Sidenav));
  };
  static ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
    type: ToolbarComponent,
    selectors: [["page-toolbar"]],
    viewQuery: function ToolbarComponent_Query(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵviewQuery(ToolbarContentDirective, 7);
      }
      if (rf & 2) {
        let _t;
        i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.content = _t.first);
      }
    },
    decls: 2,
    vars: 1,
    consts: [["mat-icon-button", "", 3, "click", 4, "ngIf"], ["toolbar-content", ""], ["mat-icon-button", "", 3, "click"]],
    template: function ToolbarComponent_Template(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵtemplate(0, ToolbarComponent_button_0_Template, 3, 0, "button", 0)(1, ToolbarComponent_ng_template_1_Template, 0, 0, "ng-template", 1);
      }
      if (rf & 2) {
        i0.ɵɵproperty("ngIf", ctx.sidenav.mode === "over");
      }
    },
    dependencies: [CommonModule, i4.NgIf, MatButtonModule, i5.MatIconButton, MatIconModule, i6.MatIcon, ToolbarContentDirective],
    styles: ["[_nghost-%COMP%]{width:100%;padding-inline:1rem;display:flex;align-items:center}button.mat-mdc-icon-button[_ngcontent-%COMP%]{flex:0 0 auto;cursor:pointer;color:inherit}.mat-icon[_ngcontent-%COMP%]:not(:last-child){margin-inline-end:1rem}"]
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ToolbarComponent, [{
    type: Component,
    args: [{
      selector: 'page-toolbar',
      imports: [CommonModule, MatButtonModule, MatIconModule, ToolbarContentDirective],
      template: "<button mat-icon-button *ngIf=\"sidenav.mode==='over'\" (click)=\"sidenav.toggle()\">\r\n  <mat-icon>menu</mat-icon>\r\n</button>\r\n\r\n<ng-template toolbar-content></ng-template>\r\n",
      styles: [":host{width:100%;padding-inline:1rem;display:flex;align-items:center}button.mat-mdc-icon-button{flex:0 0 auto;cursor:pointer;color:inherit}.mat-icon:not(:last-child){margin-inline-end:1rem}\n"]
    }]
  }], () => [{
    type: i1.Title
  }, {
    type: Toolbar
  }, {
    type: i3.Sidenav
  }], {
    content: [{
      type: ViewChild,
      args: [ToolbarContentDirective, {
        static: true
      }]
    }]
  });
})();

/**
 * Public surface API of @public/core/ui/toolbar
 */

/**
 * Generated bundle index. Do not edit.
 */

export { Toolbar, ToolbarComponent };
