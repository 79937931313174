import { Component, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'header-content',
  templateUrl: './header-content.component.html',
  styleUrls: ['./header-content.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: {'class': 'header-content'}
})
export class HeaderContentComponent {}
