<page-layout>
  <header color="primary">
    <header-content>
      <div>
        <h1>Betingelser for brug af {{ windowService.getHostname() }}</h1>
        <h2>Gældende fra 4. juli 2018</h2>
      </div>
    </header-content>
  </header>

  <main>
    <p>
      Denne hjemmeside stilles til rådighed af Mushindo. Hvis du har kommentarer eller spørgsmål bedes du venligst
      henvende dig til os på e-mail adressen webmaster&#64;mushindo.dk eller telefon 4040 2528.
    </p>

    <h2>Indhold</h2>

    <p>
      Vi bestræber os på at sikre, at hjemmesidens indhold til enhver tid er korrekt og ajourført. Vi har mulighed for
      at ændre indholdet af hjemmesiden, herunder fjerne dele af indholdet, efter eget skøn og uden forudgående varsel.
      Vi garanterer ikke for, at de oplysninger, som fremgår af hjemmesiden, er korrekte eller fyldestgørende.
    </p>

    <h2>Ansvarsbegrænsning</h2>

    <p>
      Enhver brug af denne hjemmeside sker på eget ansvar og risiko. Indholdet af denne hjemmeside og al materiale på
      hjemmesiden stilles til rådighed som beset og uden nogen form for hverken stiltiende eller udtrykkelig garantier.
    </p>
    <p>
      Anvendelse af oplysninger på hjemmesiden, f.eks. i forbindelse med trænning sker på brugerens eget ansvar.
      Mushindo kan således ikke stilles til ansvar for skader eller lignende, der er opstået som følge af oplysninger på
      hjemmesiden.
    </p>
    <p>
      Vi bestræber sig på at sikre, at hjemmesiden fungerer tilfredsstillende til enhver tid, men kan ikke garantere, at
      forbindelsen til denne hjemmeside ikke vil blive afbrudt, at den vil være sikker og uden fejl, og at disse vil
      blive rettet, eller at der ikke vil kunne findes vira eller andre fejl på serveren.
    </p>
    <p>
      Brugeren må tage sine egne forholdsregler for at sikre sig mod tab eller skade. Dette omfatter bl.a. installering
      af nødvendige sikkerhedsforanstaltninger for at sikre sig mod vira og at sikre opdaterede kopier af alle data.
    </p>

    <h2>Immaterielle rettigheder</h2>

    <p>
      Alle billeder, tekster logoer m.v. på siden tilhører Mushindo, eller vi har fået tilladelse til at anvende
      billedet/logoet på siden. Materialet på hjemmesiden må ikke kopieres eller anvendes i anden kontekst uden
      forudgående skriftlig aftale med Mushindo. Det betyder, at du ikke på anvende et af vores billeder på din egen
      hjemmeside, heller ikke ved blot at referere til billedfilen på vores server.
    </p>
    <p>Du må dog gerne linke direkte til en af vores undersider fra din egen hjemmeside.</p>

    <h2>Links til andre hjemmesider</h2>

    <p>
      Mushindo anvender i et vist omfang links til andre hjemmesider. Disse links stilles til rådighed som en service
      til brugerne. Vi foretager ingen kontrol af sådanne eksterne hjemmesider, og vi påtager os ikke nogen form for
      ansvar for sådanne hjemmesider eller deres indhold. Links til andre hjemmesider er ikke udtryk for, at vi
      godkender eller anbefaler disse.
    </p>

    <h2>Sikkerhedsforanstaltninger</h2>

    <p>
      Hjemmesiden indholder en række sikkerhedsforanstaltninger, der bl.a. skal sikre, at kun medlemmer har adgang til
      visse oplyninger. Du ikke, hverken teknisk eller på anden måske forsøge at omgå disse. For at håndhæve dette
      registerer vi din brug af siden, herunder hvilken IP adresse forspørgsler bliver sendt fra og hvornår du logger
      ind og ud.
    </p>
    <p>
      Forsøg på at omgå sikkerhedsforanstaltningerne vil medføre udelukkelse fra deltagelse i Mushindos aktiviteter og
      vil i grove tilfælde blive politianmeldt.
    </p>
  </main>

  <footer color="tertiary">
    <footer-content></footer-content>
  </footer>
</page-layout>
