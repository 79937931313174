import { Component } from '@angular/core';

import { WindowService } from '@public/core';
import { ContentComponent, FooterComponent, HeaderComponent, LayoutComponent } from '@public/core/ui/layout';

import { FooterContentComponent, HeaderContentComponent } from '@mushindo/common';

@Component({
  selector: 'privacy-policy',
  templateUrl: './privacy-policy.component.html',
  imports: [
    ContentComponent,
    FooterComponent,
    HeaderComponent,
    LayoutComponent,
    
    FooterContentComponent,
    HeaderContentComponent,
  ],
})
export class PrivacyPolicyComponent {
  constructor(public windowService: WindowService) {}
}
