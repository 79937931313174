import { Component } from '@angular/core';

import { MatDividerModule } from '@angular/material/divider';

@Component({
  selector: 'footer-content',
  templateUrl: './footer-content.component.html',
  styleUrls: ['./footer-content.component.scss'],
  imports: [ MatDividerModule]
})
export class FooterContentComponent {}
