import { Component, Inject } from '@angular/core';
import { RouterModule } from '@angular/router';

import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule,MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { LocalStorage } from '@public/core';

@Component({
  templateUrl: './cookie-dialog.component.html',
  styleUrls: ['./cookie-dialog.component.scss'],
  imports: [RouterModule, MatButtonModule, MatDialogModule],
})
export class CookieDialogComponent {
  constructor(
    private localStorage: LocalStorage,
    private dialog: MatDialogRef<CookieDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  public ok() {
    this.localStorage.set('cookiesAccepted', new Date().toISOString());
    this.dialog.close();
  }
}
