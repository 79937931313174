import { Component } from '@angular/core';

import { ContentComponent, FooterComponent, HeaderComponent, LayoutComponent } from '@public/core/ui/layout';

import { FooterContentComponent } from './footer-content.component';
import { HeaderContentComponent } from './header-content.component';

@Component({
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss'],
  imports: [
    ContentComponent,
    FooterComponent,
    HeaderComponent,
    LayoutComponent,

    FooterContentComponent,
    HeaderContentComponent,
  ],
})
export class NotFoundComponent {}
