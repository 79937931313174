<page-layout>
  <div class="background" [ngClass]="{ 'sidenav-over': mode === 'over' }"></div>

  <main [ngClass]="{ 'fade-in': !visited }">
    <div class="grid">
      <mat-card>
        <mat-card-header>
          <mat-card-title>Kommende gradueringer</mat-card-title>
          <div class="divider"></div>
        </mat-card-header>
        <mat-card-content>
          <p>
            Sæt allerede nu kryds i kalenderen, for vi holder gradueringer for juniorer og voksne søndag den 16. marts
            fra kl. 17:00 til omkring 19:30.
          </p>
          <p>Graduering på børneholdet bliver den efterfølgende torsdag den 20. marts i træningstiden.</p>
        </mat-card-content>
        <mat-card-actions>
          <a routerLink="/klubben/graduering">
            <button mat-button>Læs mere om graduering<mat-icon>chevron_right</mat-icon></button>
          </a>
        </mat-card-actions>
      </mat-card>

      <mat-card>
        <mat-card-header>
          <mat-card-title>Tid til kontingentbetaling</mat-card-title>
          <div class="divider"></div>
        </mat-card-header>
        <mat-card-content>
          <ng-container *ngIf="!isMobile()">
            <p>Betaling foregår via MobilePay til nummer 27294 eller ved at scanne nedenstående QR kode:</p>
            <img
              src="/assets/graphics/mobilepay-qr.png"
              style="width: 10rem; margin-inline: auto; margin-block-end: 1rem"
            />
          </ng-container>

          <ng-container *ngIf="isMobile()">
            <p>Betaling foregår via MobilePay til nummer 27294 eller ved at trykke her:</p>
            <a href="https://qr.mobilepay.dk/28/2/05/031/JL7Xsg1-p" style="margin-inline: auto; margin-block-end: 1rem">
              <img src="/assets/graphics/mobilepay-with.png" />
            </a>
          </ng-container>

          <p>
            Alternativt kan du lave en bankoverførsel til vores bankkonto i Sparekassen Danmark, kontonummer 9070
            1640582525. Angiv da venligst hvem du betaler for i beskedfeltet.
          </p>
        </mat-card-content>
        <mat-card-actions>
          <a routerLink="/klubben/tider-og-priser">
            <button mat-button>Læs mere<mat-icon>chevron_right</mat-icon></button>
          </a>
        </mat-card-actions>
      </mat-card>

      <mat-card>
        <mat-card-header>
          <mat-card-title>Forårssæsonen er startet</mat-card-title>
          <div class="divider"></div>
        </mat-card-header>
        <mat-card-content>
          <p>...og vi glæder os til at se jer alle til træning.</p>
          <p>
            Det er samtidigt et godt tidpunkt at starte på, hvis du har lyst til eller kender nogen, der har lyst til at
            prøve kræfter med karate og kobudo.
          </p>
        </mat-card-content>
        <mat-card-actions>
          <a routerLink="/klubben/tider-og-priser">
            <button mat-button>Hold, tider og priser<mat-icon>chevron_right</mat-icon></button>
          </a>
        </mat-card-actions>
      </mat-card>

      <!--
      <mat-card>
        <mat-card-header>
          <mat-card-title>Generalforsamling 2025</mat-card-title>
          <div class="divider"></div>
        </mat-card-header>
        <mat-card-content>
          <p>Generalforsamling 2025 vil blive afholdt den 19. januar 2025 kl. 15:00 i musiklokalet på Gl. Rye skole.</p>
          <a routerLink="/klubben/generalforsamling">
            <button mat-button>Dagsorden m.v.<mat-icon>chevron_right</mat-icon></button>
          </a></mat-card-content>
      </mat-card>

      <mat-card>
        <mat-card-header>
          <mat-card-title>Juniordag lørdag den 2/11</mat-card-title>
          <div class="divider"></div>
        </mat-card-header>
        <mat-card-content>
          <p>
            Vi holder "juniordag" for alle juniorer lørdag den 2/11 fra kl. 13 i hallen. Vi starter med graduering, for
           dem som skal det og træning for alle andre. Omkring kl. 18 er der aftensmad i skoleklubben og derefter
           yderligere (anderledes) træning. Vi slutter af med hygge i klubben indtil kl. 22.
          </p>
          <p>
            Husk at medbringe drikkedunk og skiftetøj. Vi sørger for aftensmad (pizza), snacks og sodavand til om aftenen
            og forhåbentlig er der en forælder eller to, som vil komme med noget kage :-)
          </p>
        </mat-card-content>
        <mat-card-actions>
          <a href="https://www.facebook.com/groups/148462588657674" target="_new">
            <button mat-button>Læs mere<mat-icon>chevron_right</mat-icon></button>
          </a>
        </mat-card-actions>
      </mat-card>
      <mat-card>
        <mat-card-header>
          <mat-card-title>Tid til kontingentbetaling</mat-card-title>
          <div class="divider"></div>
        </mat-card-header>
        <mat-card-content>
          <ng-container *ngIf="!isMobile()">
            <p>Betaling foregår via MobilePay til nummer 27294 eller ved at scanne nedenstående QR kode:</p>
            <img
              src="/assets/graphics/mobilepay-qr.png"
              style="width: 10rem; margin-inline: auto; margin-block-end: 1rem"
            />
          </ng-container>

          <ng-container *ngIf="isMobile()">
            <p>Betaling foregår via MobilePay til nummer 27294 eller ved at trykke her:</p>
            <a href="https://qr.mobilepay.dk/28/2/05/031/JL7Xsg1-p" style="margin-inline: auto; margin-block-end: 1rem">
              <img src="/assets/graphics/mobilepay-with.png" />
            </a>
          </ng-container>

          <p>
            Alternativt kan du lave en bankoverførsel til vores bankkonto i Sparekassen Danmark, kontonummer 9070
            1640582525. Angiv da venligst hvem du betaler for i beskedfeltet.
          </p>
        </mat-card-content>
        <mat-card-actions>
          <a routerLink="/klubben/tider-og-priser">
            <button mat-button>Læs mere<mat-icon>chevron_right</mat-icon></button>
          </a>
        </mat-card-actions>
      </mat-card>
      <mat-card>
        <mat-card-header>
          <mat-card-title>Bliv medlem!</mat-card-title>
          <div class="divider"></div>
        </mat-card-header>
        <mat-card-content>
          <p>
            Har du lyst til at træne sammen med os, så tøv ikke - meld dig ind med det samme <a routerLink="/klubben/indmeldelse">her.</a>
          </p>
          <p>
            Har du brug for mere information om træningen og hvor og hvornår vi træner, så klik på nedenstående.
          </p>
      </mat-card-content>
        <mat-card-actions>
          <a routerLink="/klubben/træningen">
            <button mat-button>Træningen<mat-icon>chevron_right</mat-icon></button>
          </a>
          <a routerLink="/klubben/tider-og-priser">
            <button mat-button>Hold og tider<mat-icon>chevron_right</mat-icon></button>
          </a>
        </mat-card-actions>
      </mat-card>

      <mat-card>
        <mat-card-header>
          <mat-card-title>Så starter den normale træning igen!</mat-card-title>
          <div class="divider"></div>
        </mat-card-header>
        <mat-card-content>
          <p>Skolernes sommerferie er ved at nærme sig sin afslutning og den normale træning starter igen.</p>
          <p style="font-size: 2rem;"> {{ '&#129355;' }}</p>
          <p>
            I uge 33 starter junior- og voksenholdet tirsdag den 13. fra kl. 18 til 19, børneholdet torsdag den 15.
            ligeledes fra kl. 18 til 19 og sempai holdet fra kl. 19 til 21.
          </p>
          <p>Vi ses til træning - vel mødt</p>
        </mat-card-content>
        <mat-card-actions>
          <a routerLink="/klubben/tider-og-priser">
            <button mat-button>Læs mere<mat-icon>chevron_right</mat-icon></button>
          </a>
        </mat-card-actions>
      </mat-card>

      <mat-card>
        <mat-card-header>
          <mat-card-title>Vi holder sommerferie</mat-card-title>
          <div class="divider"></div>
        </mat-card-header>
        <mat-card-content>
          <p>
            Vi holder sommerferie fra den normale træning, men træner ind i mellem alligevel. Følg med på vores <a href="https://www.facebook.com/groups/148462588657674" target="_new">Facebook
            gruppe</a>, hvor vi løbende vil annoncere hvor og hvornår der er træning.
          </p>
          <p>Den normale træning starter igen efter skolernes sommerferie, i uge 34, muligvis allerede i uge 33. Igen, følg med i vores <a href="https://www.facebook.com/groups/148462588657674" target="_new">Facebook gruppe</a>.</p>
          <p>God sommer til alle børn og deres forældre, juniorer og voksne.</p>
        </mat-card-content>
      </mat-card>

      <mat-card>
        <mat-card-header>
          <mat-card-title>Mushindo bliver en selvstændig forening</mat-card-title>
          <div class="divider"></div>
        </mat-card-header>
        <mat-card-content>
          <p>
            Efter mange gode år i Gl. Rye Idrætsforening (GRIF) har Mushindos afdelingsudvalg besluttet at blive en
            selvstændig forening. Beslutningen er truffet efter en grundig vurdering af diskussionen af en ny struktur i
            GRIF, som ikke matcher Mushindos visioner for fremtiden. Som selvstændig forening kan Mushindo bedre
            fokusere på at tilbyde et attraktivt og varieret træningsmiljø for medlemmer i alle aldre. Vi forventer ikke
            at dette vil medføre væsentlige ændringer for vores medlemmer, da den daglige træning fortsætter som hidtil.
          </p>
          <p>Vi ses til træning - vel mødt</p>
        </mat-card-content>
      </mat-card>

      <mat-card>
        <mat-card-header>
          <mat-card-title>Stiftende generalforsamling</mat-card-title>
          <div class="divider"></div>
        </mat-card-header>
        <mat-card-content>
          <p>
            Torsdag d. 21. marts 2024 kl. 19 afholdte vi stiftende generalforsamling med følgende dagsorden:
          </p>

          <ol style="text-align: left">
            <li>Valg af dirigent</li>
            <li>Valg af referent</li>
            <li>Redegørelse for baggrunden for foreningens stiftelse</li>
            <li>Forelæggelse af vedtægter til godkendelse</li>
            <li>Forslag til budget og kontingent</li>
            <li>Valg af foreningens bestyrelse</li>
            <li>Valg af revisor</li>
            <li>Eventuelt</li>
          </ol>
        </mat-card-content>
        <mat-card-actions>
          <a href="/assets/downloads/mushindo/referat - stiftende generalforsamling.pdf" download="mushindo-stiftende-generalforsamling.pdf">
            <button mat-button>Download referat som PDF<mat-icon>download</mat-icon></button>
          </a>
        </mat-card-actions>
      </mat-card>
      -->
    </div>
  </main>

  <footer [ngClass]="{ 'fade-in': !visited }" color="tertiary">
    <footer-content></footer-content>
  </footer>
</page-layout>
