import * as i0 from '@angular/core';
import { Component, ViewChild, Injectable, Optional, SkipSelf, InjectionToken, Inject, Pipe } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { MatSidenav, MatSidenavContainer, MatSidenavContent } from '@angular/material/sidenav';
import { MatToolbar, MatToolbarRow } from '@angular/material/toolbar';
import { ReplaySubject, throwError, BehaviorSubject, Subject } from 'rxjs';
import { takeUntil, catchError } from 'rxjs/operators';
import * as i1 from '@public/core/ui/sidenav';
import { SidenavComponent } from '@public/core/ui/sidenav';
import { ToolbarComponent } from '@public/core/ui/toolbar';
import { HttpErrorResponse } from '@angular/common/http';
import * as i1$1 from '@angular/material/snack-bar';
import * as i3 from '@angular/service-worker';
const _c0 = ["sidenav"];
const _c1 = ["toolbar"];
class RootComponent {
  sidenav;
  matSidenav;
  matToolbar;
  destroyer = new ReplaySubject(1);
  constructor(sidenav) {
    this.sidenav = sidenav;
  }
  /**
   *
   */
  ngOnInit() {
    this.sidenav.stateChange.pipe(takeUntil(this.destroyer)).subscribe(state => {
      if (this.matSidenav) {
        if (state === 'open') {
          this.matSidenav.open();
        } else {
          this.matSidenav.close();
        }
      }
    });
    this.sidenav.modeChange.pipe(takeUntil(this.destroyer)).subscribe(mode => {
      if (this.matSidenav) {
        if (mode) {
          this.matSidenav.mode = mode;
        }
      }
    });
  }
  /**
   *
   */
  ngOnDestroy() {
    this.destroyer.next(true);
    this.destroyer.complete();
  }
  static ɵfac = function RootComponent_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || RootComponent)(i0.ɵɵdirectiveInject(i1.Sidenav));
  };
  static ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
    type: RootComponent,
    selectors: [["page-root"]],
    viewQuery: function RootComponent_Query(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵviewQuery(_c0, 7);
        i0.ɵɵviewQuery(_c1, 7);
      }
      if (rf & 2) {
        let _t;
        i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.matSidenav = _t.first);
        i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.matToolbar = _t.first);
      }
    },
    decls: 10,
    vars: 0,
    consts: [["toolbar", ""], ["sidenav", ""], ["autosize", ""]],
    template: function RootComponent_Template(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵelementStart(0, "mat-toolbar", null, 0)(2, "mat-toolbar-row");
        i0.ɵɵelement(3, "page-toolbar");
        i0.ɵɵelementEnd()();
        i0.ɵɵelementStart(4, "mat-sidenav-container", 2)(5, "mat-sidenav", null, 1);
        i0.ɵɵelement(7, "page-sidenav");
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(8, "mat-sidenav-content");
        i0.ɵɵelement(9, "router-outlet");
        i0.ɵɵelementEnd()();
      }
    },
    dependencies: [RouterOutlet, MatSidenav, MatSidenavContainer, MatSidenavContent, MatToolbar, MatToolbarRow, SidenavComponent, ToolbarComponent],
    styles: [".mat-toolbar[_ngcontent-%COMP%]{position:fixed;z-index:4;box-shadow:var(--mat-sys-level4)}.mat-toolbar-row[_ngcontent-%COMP%]{padding:0}.mat-sidenav-container[_ngcontent-%COMP%]{position:relative}.mat-sidenav[_ngcontent-%COMP%]{position:fixed;top:64px;bottom:0;left:0;padding:0;width:16rem;border-radius:0;box-shadow:var(--mat-sys-level2)}.mat-sidenav-content[_ngcontent-%COMP%]{padding-top:64px}@media (max-width: 600px){.mat-sidenav[_ngcontent-%COMP%]{top:56px}.mat-sidenav-content[_ngcontent-%COMP%]{padding-top:56px}}"]
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(RootComponent, [{
    type: Component,
    args: [{
      selector: 'page-root',
      imports: [RouterOutlet, MatSidenav, MatSidenavContainer, MatSidenavContent, MatToolbar, MatToolbarRow, SidenavComponent, ToolbarComponent],
      template: "<mat-toolbar #toolbar>\r\n  <mat-toolbar-row>\r\n    <page-toolbar/>\r\n  </mat-toolbar-row>\r\n</mat-toolbar>\r\n\r\n<mat-sidenav-container autosize>\r\n  <mat-sidenav #sidenav>\r\n    <page-sidenav/>\r\n  </mat-sidenav>\r\n\r\n  <mat-sidenav-content>\r\n    <router-outlet/>\r\n  </mat-sidenav-content>\r\n</mat-sidenav-container>\r\n",
      styles: [".mat-toolbar{position:fixed;z-index:4;box-shadow:var(--mat-sys-level4)}.mat-toolbar-row{padding:0}.mat-sidenav-container{position:relative}.mat-sidenav{position:fixed;top:64px;bottom:0;left:0;padding:0;width:16rem;border-radius:0;box-shadow:var(--mat-sys-level2)}.mat-sidenav-content{padding-top:64px}@media (max-width: 600px){.mat-sidenav{top:56px}.mat-sidenav-content{padding-top:56px}}\n"]
    }]
  }], () => [{
    type: i1.Sidenav
  }], {
    matSidenav: [{
      type: ViewChild,
      args: ['sidenav', {
        static: true
      }]
    }],
    matToolbar: [{
      type: ViewChild,
      args: ['toolbar', {
        static: true
      }]
    }]
  });
})();
class SingletonError extends Error {
  constructor(instance) {
    super(`${instance.constructor.name} already instantiated. Provide it only in the root module in order to ensure it is a singeton.`);
  }
}

// Import Angular stuff
/**
 *
 */
class AlertService {
  snackBar;
  instance;
  constructor(snackBar, instance) {
    this.snackBar = snackBar;
    this.instance = instance;
    if (instance) {
      throw new SingletonError(this);
    }
  }
  /**
   *
   * @returns MatSnackBarRef<SimpleSnackBar>
   */
  success(message, timeout, acknowledge) {
    const config = {
      panelClass: 'success'
    };
    config.duration = timeout ? timeout : undefined;
    return this.open(message, config, acknowledge);
  }
  /**
   *
   * @returns MatSnackBarRef<SimpleSnackBar>
   */
  info(message, timeout, acknowledge) {
    const config = {
      panelClass: 'info'
    };
    config.duration = timeout ? timeout : undefined;
    return this.open(message, config, acknowledge);
  }
  /**
   *
   * @returns MatSnackBarRef<SimpleSnackBar>
   */
  warning(message, timeout, acknowledge) {
    const config = {
      panelClass: 'warning'
    };
    config.duration = timeout ? timeout : undefined;
    return this.open(message, config, acknowledge);
  }
  /**
   *
   * @returns MatSnackBarRef<SimpleSnackBar>
   */
  error(message, timeout, acknowledge) {
    const config = {
      panelClass: 'error'
    };
    config.duration = timeout ? timeout : undefined;
    return this.open(message, config, acknowledge);
  }
  /**
   *
   * @param message
   * @param config
   * @param acknowledge
   */
  open(message, config, acknowledge) {
    return this.snackBar.open(message, acknowledge ? typeof acknowledge === 'string' ? acknowledge : 'OK' : config.timeout ? '' : 'OK', config);
  }
  static ɵfac = function AlertService_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || AlertService)(i0.ɵɵinject(i1$1.MatSnackBar), i0.ɵɵinject(AlertService, 12));
  };
  static ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
    token: AlertService,
    factory: AlertService.ɵfac,
    providedIn: 'root'
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AlertService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: i1$1.MatSnackBar
  }, {
    type: AlertService,
    decorators: [{
      type: Optional
    }, {
      type: SkipSelf
    }]
  }], null);
})();

// Import Angular stuff
const GENERAL_SERVER_ERROR = 'General server error';
const CONNECTION_ERROR = 'Connection error';
class ErrorInterceptor {
  alertService;
  constructor(alertService) {
    this.alertService = alertService;
  }
  intercept(request, next) {
    return next.handle(request).pipe(catchError(error => {
      if (error instanceof HttpErrorResponse) {
        if (error.status === 0) {
          this.alertService.error(CONNECTION_ERROR);
        } else if (error.status === 500) {
          this.alertService.error(GENERAL_SERVER_ERROR);
        }
      }
      return throwError(error);
    }));
  }
  static ɵfac = function ErrorInterceptor_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || ErrorInterceptor)(i0.ɵɵinject(AlertService));
  };
  static ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
    token: ErrorInterceptor,
    factory: ErrorInterceptor.ɵfac
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ErrorInterceptor, [{
    type: Injectable
  }], () => [{
    type: AlertService
  }], null);
})();

// Import Angular stuff
class ApplicationStorage {
  instance;
  static store = new Map();
  constructor(instance) {
    this.instance = instance;
    if (instance) {
      throw new SingletonError(instance);
    }
  }
  get(key) {
    return ApplicationStorage.store.get(key);
  }
  set(key, value) {
    // Remove the item from the store map if the value is null or undefined.
    if (!value) {
      ApplicationStorage.store.delete(key);
      return;
    }
    ApplicationStorage.store.set(key, value);
  }
  static ɵfac = function ApplicationStorage_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || ApplicationStorage)(i0.ɵɵinject(ApplicationStorage, 12));
  };
  static ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
    token: ApplicationStorage,
    factory: ApplicationStorage.ɵfac,
    providedIn: 'root'
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ApplicationStorage, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: ApplicationStorage,
    decorators: [{
      type: Optional
    }, {
      type: SkipSelf
    }]
  }], null);
})();
class DateTimeService {
  instance;
  constructor(instance) {
    this.instance = instance;
    if (instance) {
      throw new SingletonError(this);
    }
  }
  format(value) {
    return value ? new Date(value).toISOString() : undefined;
  }
  parse(value) {
    return value ? Date.parse(value) : undefined;
  }
  static ɵfac = function DateTimeService_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || DateTimeService)(i0.ɵɵinject(DateTimeService, 12));
  };
  static ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
    token: DateTimeService,
    factory: DateTimeService.ɵfac,
    providedIn: 'root'
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DateTimeService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: DateTimeService,
    decorators: [{
      type: Optional
    }, {
      type: SkipSelf
    }]
  }], null);
})();

// Import Angular stuff
class LocalStorage {
  instance;
  constructor(instance) {
    this.instance = instance;
    if (instance) {
      throw new SingletonError(instance);
    }
  }
  get(key) {
    const raw = window.localStorage.getItem(key);
    return raw ? JSON.parse(raw) : undefined;
  }
  set(key, value) {
    // Remove the item from the browser's local storage if value is null or
    // undefined.
    if (!value) {
      window.localStorage.removeItem(key);
      return;
    }
    window.localStorage.setItem(key, JSON.stringify(value));
  }
  static ɵfac = function LocalStorage_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || LocalStorage)(i0.ɵɵinject(LocalStorage, 12));
  };
  static ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
    token: LocalStorage,
    factory: LocalStorage.ɵfac,
    providedIn: 'root'
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(LocalStorage, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: LocalStorage,
    decorators: [{
      type: Optional
    }, {
      type: SkipSelf
    }]
  }], null);
})();
class PositionService {
  instance;
  get positionState() {
    return this.positionStateSource.value;
  }
  get positionStateChange() {
    return this.positionStateSource.asObservable();
  }
  get position() {
    return this.positionChangeSource.value;
  }
  get positionChange() {
    return this.positionChangeSource.asObservable();
  }
  positionStateSource = new BehaviorSubject(undefined);
  positionChangeSource = new BehaviorSubject(undefined);
  constructor(instance) {
    this.instance = instance;
    if (instance) {
      throw new SingletonError(this);
    }
    if (!navigator.geolocation) {
      this.positionStateSource.next('UNSUPPORTED');
      return;
    }
    navigator.geolocation.getCurrentPosition(this.onPositionSuccess, this.onPositionFailure);
    navigator.geolocation.watchPosition(this.onPositionSuccess, this.onPositionFailure);
  }
  onPositionSuccess = position => {
    this.positionStateSource.next('AVAILABLE');
    this.positionChangeSource.next({
      latitude: position.coords.latitude,
      longitude: position.coords.longitude,
      altitude: position.coords.altitude ? position.coords.altitude : undefined,
      heading: position.coords.heading ? position.coords.heading : undefined,
      speed: position.coords.speed ? position.coords.speed : undefined,
      time: position.timestamp
    });
  };
  onPositionFailure = error => {
    if (error.code == 1) {
      this.positionStateSource.next('BLOCKED');
    } else {
      this.positionStateSource.next('UNAVAILABLE');
    }
  };
  static ɵfac = function PositionService_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || PositionService)(i0.ɵɵinject(PositionService, 12));
  };
  static ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
    token: PositionService,
    factory: PositionService.ɵfac,
    providedIn: 'root'
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PositionService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: PositionService,
    decorators: [{
      type: Optional
    }, {
      type: SkipSelf
    }]
  }], null);
})();

/** Injection token that can be used to configure the service's behavior. */
const PWA_CONFIG = new InjectionToken('PwaConfig');
const defaultConfig = {
  installPrompt: {
    promptText: 'Want to install as an app.',
    buttonText: 'Yes'
  },
  updatePrompt: {
    promptText: 'A new version of the app is available.',
    buttonText: 'Install'
  },
  timeout: 30000
};
class PwaService {
  alertService;
  snackBar;
  swUpdate;
  instance;
  destroyer = new ReplaySubject(1);
  constructor(alertService, snackBar, swUpdate, config, instance) {
    this.alertService = alertService;
    this.snackBar = snackBar;
    this.swUpdate = swUpdate;
    this.instance = instance;
    if (instance) {
      throw new SingletonError(this);
    }
    if (!config) {
      config = defaultConfig;
    }
    if (config.installPrompt) {
      window.addEventListener('beforeinstallprompt', event => {
        // Prevent the mini-infobar from appearing on mobile
        event.preventDefault();
        // Stash the event so it can be triggered later.
        const deferredPrompt = event;
        if (config.installPrompt) {
          if (config.installPrompt instanceof Component) {
            console.error('Component install prompt not yet supported');
          } else {
            this.alertService.info(config.installPrompt.promptText, config.timeout, config.installPrompt.buttonText).onAction().subscribe(() => {
              deferredPrompt.prompt();
              deferredPrompt.userChoice.then(result => {
                console.log(result);
              });
            });
          }
        }
      });
    }
    if (config.updatePrompt) {
      swUpdate.versionUpdates.pipe(takeUntil(this.destroyer)).subscribe(event => {
        switch (event.type) {
          case 'VERSION_DETECTED':
            console.info(`Downloading new app version: ${event.version.hash}`);
            break;
          case 'VERSION_READY':
            console.info(`New app version ready: ${event.latestVersion.hash}`);
            if (!config.updatePrompt) {
              break;
            }
            if (config.updatePrompt instanceof Component) {
              console.error('Component update prompt not yet supported');
            } else {
              this.alertService.info(config.updatePrompt?.promptText, config.timeout, config.updatePrompt.buttonText).onAction().subscribe(() => {
                console.log(`PwaService reloading app`);
                window.location.reload();
              });
            }
            break;
          case 'VERSION_INSTALLATION_FAILED':
            console.error(`Failed to install app version '${event.version.hash}': ${event.error}`);
            break;
        }
      });
      console.info(`PwaService listing for app updates`);
    }
  }
  ngOnDestroy() {
    this.destroyer.next(true);
    this.destroyer.complete();
  }
  static ɵfac = function PwaService_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || PwaService)(i0.ɵɵinject(AlertService), i0.ɵɵinject(i1$1.MatSnackBar), i0.ɵɵinject(i3.SwUpdate), i0.ɵɵinject(PWA_CONFIG, 8), i0.ɵɵinject(PwaService, 12));
  };
  static ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
    token: PwaService,
    factory: PwaService.ɵfac,
    providedIn: 'root'
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PwaService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: AlertService
  }, {
    type: i1$1.MatSnackBar
  }, {
    type: i3.SwUpdate
  }, {
    type: undefined,
    decorators: [{
      type: Optional
    }, {
      type: Inject,
      args: [PWA_CONFIG]
    }]
  }, {
    type: PwaService,
    decorators: [{
      type: Optional
    }, {
      type: SkipSelf
    }]
  }], null);
})();

// Import Angular stuff
class SessionStorage {
  instance;
  constructor(instance) {
    this.instance = instance;
    if (instance) {
      throw new SingletonError(instance);
    }
  }
  get(key) {
    const raw = window.localStorage.getItem(key);
    return raw ? JSON.parse(raw) : undefined;
  }
  set(key, value) {
    // Remove the item from the browser's local storage if value is null or
    // undefined.
    if (!value) {
      window.sessionStorage.removeItem(key);
      return;
    }
    window.sessionStorage.setItem(key, JSON.stringify(value));
  }
  static ɵfac = function SessionStorage_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || SessionStorage)(i0.ɵɵinject(SessionStorage, 12));
  };
  static ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
    token: SessionStorage,
    factory: SessionStorage.ɵfac,
    providedIn: 'root'
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SessionStorage, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: SessionStorage,
    decorators: [{
      type: Optional
    }, {
      type: SkipSelf
    }]
  }], null);
})();

// Import Angular stuff
const USER_KEY = 'currentUser';
class UserService {
  storage;
  instance;
  userChangeSource = new Subject();
  constructor(storage, instance) {
    this.storage = storage;
    this.instance = instance;
    if (instance) {
      throw new SingletonError(this);
    }
  }
  get userChange() {
    return this.userChangeSource.asObservable();
  }
  getCurrentUser() {
    return this.storage.get(USER_KEY);
  }
  setCurrentUser(user) {
    if (user !== this.getCurrentUser()) {
      this.userChangeSource.next(user);
    }
    this.storage.set(USER_KEY, user);
  }
  static ɵfac = function UserService_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || UserService)(i0.ɵɵinject(LocalStorage), i0.ɵɵinject(UserService, 12));
  };
  static ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
    token: UserService,
    factory: UserService.ɵfac,
    providedIn: 'root'
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(UserService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: LocalStorage
  }, {
    type: UserService,
    decorators: [{
      type: Optional
    }, {
      type: SkipSelf
    }]
  }], null);
})();

// Import Angular stuff
class WindowService {
  instance;
  constructor(instance) {
    this.instance = instance;
    if (instance) {
      throw new SingletonError(this);
    }
  }
  getHostname() {
    return window.location.hostname;
  }
  static ɵfac = function WindowService_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || WindowService)(i0.ɵɵinject(WindowService, 12));
  };
  static ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
    token: WindowService,
    factory: WindowService.ɵfac,
    providedIn: 'root'
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(WindowService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: WindowService,
    decorators: [{
      type: Optional
    }, {
      type: SkipSelf
    }]
  }], null);
})();
class DateTimePipe {
  dateTimeService;
  constructor(dateTimeService) {
    this.dateTimeService = dateTimeService;
  }
  transform(value) {
    return this.dateTimeService.format(value);
  }
  static ɵfac = function DateTimePipe_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || DateTimePipe)(i0.ɵɵdirectiveInject(DateTimeService, 16));
  };
  static ɵpipe = /* @__PURE__ */i0.ɵɵdefinePipe({
    name: "dateTime",
    type: DateTimePipe,
    pure: true
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DateTimePipe, [{
    type: Pipe,
    args: [{
      name: 'dateTime'
    }]
  }], () => [{
    type: DateTimeService
  }], null);
})();
const ENVIRONMENT = new InjectionToken('Environment');

// Public surface of @public/core entry point

/**
 * Generated bundle index. Do not edit.
 */

export { AlertService, ApplicationStorage, DateTimePipe, DateTimeService, ENVIRONMENT, ErrorInterceptor, LocalStorage, PWA_CONFIG, PositionService, PwaService, RootComponent, SessionStorage, SingletonError, UserService, WindowService };
