import * as i0 from '@angular/core';
import { Pipe, Injectable, SkipSelf, Optional, Component, Input } from '@angular/core';
import { SingletonError } from '@public/core';
import * as i1 from '@angular/common/http';
import * as i1$1 from '@angular/material/button';
import { MatButtonModule } from '@angular/material/button';
import * as i2 from '@angular/material/card';
import { MatCardModule } from '@angular/material/card';
import * as i3 from '@angular/material/icon';
import { MatIconModule } from '@angular/material/icon';
class CreatedPipe {
  transform(data) {
    return data.creationTime.substr(0, 19).replace('T', ' ') + (data.creationUser ? ' by ' + data.creationUser.realname : '');
  }
  static ɵfac = function CreatedPipe_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || CreatedPipe)();
  };
  static ɵpipe = /* @__PURE__ */i0.ɵɵdefinePipe({
    name: "created",
    type: CreatedPipe,
    pure: true
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CreatedPipe, [{
    type: Pipe,
    args: [{
      name: 'created'
    }]
  }], null, null);
})();
class KeyPipe {
  transform(data) {
    return data.key.substr(0, 8);
  }
  static ɵfac = function KeyPipe_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || KeyPipe)();
  };
  static ɵpipe = /* @__PURE__ */i0.ɵɵdefinePipe({
    name: "key",
    type: KeyPipe,
    pure: true
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(KeyPipe, [{
    type: Pipe,
    args: [{
      name: 'key'
    }]
  }], null, null);
})();
class UpdatedPipe {
  transform(data) {
    return data.modifierTime.substr(0, 19).replace('T', ' ') + (data.modifierUser ? ' by ' + data.modifierUser.realname : '');
  }
  static ɵfac = function UpdatedPipe_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || UpdatedPipe)();
  };
  static ɵpipe = /* @__PURE__ */i0.ɵɵdefinePipe({
    name: "updated",
    type: UpdatedPipe,
    pure: true
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(UpdatedPipe, [{
    type: Pipe,
    args: [{
      name: 'updated'
    }]
  }], null, null);
})();
class RestService {
  httpClient;
  API_PREFIX = '/api';
  constructor(httpClient, instance) {
    // Guard to make sure only one instance exist in the application.
    this.httpClient = httpClient;
    if (instance) {
      throw new SingletonError(this);
    }
  }
  query(path, params) {
    if (params) {
      return this.httpClient.get(`${this.API_PREFIX}/${path}?${params}`);
    } else {
      return this.httpClient.get(`${this.API_PREFIX}/${path}`);
    }
  }
  get(path, key) {
    return this.httpClient.get(`${this.API_PREFIX}/${path}/${key}`);
  }
  create(path, payload) {
    return this.httpClient.post(`${this.API_PREFIX}/${path}`, payload);
  }
  update(path, key, payload) {
    return this.httpClient.put(`${this.API_PREFIX}/${path}/${key}`, payload);
  }
  patch(path, key, payload) {
    return this.httpClient.patch(`${this.API_PREFIX}/${path}/${key}`, payload);
  }
  delete(path, key) {
    return this.httpClient.delete(`${this.API_PREFIX}/${path}/${key}`);
  }
  static ɵfac = function RestService_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || RestService)(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(RestService, 12));
  };
  static ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
    token: RestService,
    factory: RestService.ɵfac,
    providedIn: 'root'
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(RestService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: i1.HttpClient
  }, {
    type: RestService,
    decorators: [{
      type: SkipSelf
    }, {
      type: Optional
    }]
  }], null);
})();
class UserService extends RestService {
  PATH = 'users';
  me() {
    return this.get(this.PATH, 'me');
  }
  changePassword(user, oldPassword, newPassword) {
    return this.patch(this.PATH, user.key, {
      oldPassword,
      newPassword
    });
  }
  static ɵfac = /* @__PURE__ */(() => {
    let ɵUserService_BaseFactory;
    return function UserService_Factory(__ngFactoryType__) {
      return (ɵUserService_BaseFactory || (ɵUserService_BaseFactory = i0.ɵɵgetInheritedFactory(UserService)))(__ngFactoryType__ || UserService);
    };
  })();
  static ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
    token: UserService,
    factory: UserService.ɵfac,
    providedIn: 'root'
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(UserService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], null, null);
})();
class CarouselComponent {
  images = [];
  height;
  currentImage = 0;
  prev() {
    this.currentImage = this.currentImage > 0 ? --this.currentImage : this.currentImage;
  }
  next() {
    this.currentImage = this.currentImage < this.images.length - 1 ? ++this.currentImage : this.currentImage;
  }
  static ɵfac = function CarouselComponent_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || CarouselComponent)();
  };
  static ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
    type: CarouselComponent,
    selectors: [["app-carousel"]],
    inputs: {
      images: "images",
      height: "height"
    },
    decls: 10,
    vars: 5,
    consts: [[3, "src"], ["align", "end"], ["mat-button", "", 3, "click", "disabled"]],
    template: function CarouselComponent_Template(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵelementStart(0, "mat-card")(1, "mat-card-content");
        i0.ɵɵelement(2, "img", 0);
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(3, "mat-card-actions", 1)(4, "button", 2);
        i0.ɵɵlistener("click", function CarouselComponent_Template_button_click_4_listener() {
          return ctx.prev();
        });
        i0.ɵɵelementStart(5, "mat-icon");
        i0.ɵɵtext(6, "chevron_left");
        i0.ɵɵelementEnd()();
        i0.ɵɵelementStart(7, "button", 2);
        i0.ɵɵlistener("click", function CarouselComponent_Template_button_click_7_listener() {
          return ctx.next();
        });
        i0.ɵɵelementStart(8, "mat-icon");
        i0.ɵɵtext(9, "chevron_right");
        i0.ɵɵelementEnd()()()();
      }
      if (rf & 2) {
        i0.ɵɵadvance(2);
        i0.ɵɵstyleProp("height", ctx.height);
        i0.ɵɵproperty("src", ctx.images[ctx.currentImage], i0.ɵɵsanitizeUrl);
        i0.ɵɵadvance(2);
        i0.ɵɵproperty("disabled", ctx.currentImage === 0);
        i0.ɵɵadvance(3);
        i0.ɵɵproperty("disabled", ctx.currentImage === ctx.images.length - 1);
      }
    },
    dependencies: [MatButtonModule, i1$1.MatButton, MatCardModule, i2.MatCard, i2.MatCardActions, i2.MatCardContent, MatIconModule, i3.MatIcon],
    styles: ["img[_ngcontent-%COMP%]{object-fit:contain;object-position:center;height:200px;width:100%}"]
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CarouselComponent, [{
    type: Component,
    args: [{
      selector: 'app-carousel',
      imports: [MatButtonModule, MatCardModule, MatIconModule],
      template: "<mat-card>\r\n  <mat-card-content>\r\n    <img [style.height]=\"height\" [src]=\"images[currentImage]\" />\r\n  </mat-card-content>\r\n\r\n  <mat-card-actions align=\"end\">\r\n    <button mat-button [disabled]=\"currentImage===0\" (click)=\"prev()\">\r\n      <mat-icon>chevron_left</mat-icon>\r\n    </button>\r\n    <button mat-button [disabled]=\"currentImage===images.length-1\"(click)=\"next()\">\r\n      <mat-icon>chevron_right</mat-icon>\r\n    </button>\r\n  </mat-card-actions>\r\n</mat-card>\r\n",
      styles: ["img{object-fit:contain;object-position:center;height:200px;width:100%}\n"]
    }]
  }], null, {
    images: [{
      type: Input
    }],
    height: [{
      type: Input
    }]
  });
})();

/**
 * Public surface API of @platform/shared
 */

/**
 * Generated bundle index. Do not edit.
 */

export { CarouselComponent, CreatedPipe, KeyPipe, RestService, UpdatedPipe, UserService };
