import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { MatIconModule } from '@angular/material/icon';
import { MatDividerModule } from '@angular/material/divider';
import { MatListModule } from '@angular/material/list';
import { MatSnackBarModule } from '@angular/material/snack-bar';

import { User } from '@platform/shared';
import { AuthenticationService } from '@platform/shared/authentication';

@Component({
  templateUrl: './sidenav-content.component.html',
  styleUrls: ['./sidenav-content.component.scss'],
  imports: [CommonModule, RouterModule, MatDividerModule, MatIconModule, MatListModule, MatSnackBarModule],
})
export class SidenavContentComponent implements OnInit {
  @Input()
  protected expanded?: string;

  protected currentUser?: User;

  constructor(
    private authenticationService: AuthenticationService
  ) {}

  get userIsMember(): boolean {
    return !!this.currentUser;
  }

  get userIsInstructor(): boolean {
    const user: any = this.currentUser;
    return user ? user.roles.find((item: any) => item.code === 'INSTRUCTOR') : false;
  }

  public ngOnInit() {
    this.currentUser = this.authenticationService.getCurrentUser();

    this.authenticationService.authenticationStateChange.subscribe({
      next: (user?: User) => {
        this.currentUser = user;
      },
    });
  }

  public toggle(submenu: string): boolean {
    this.expanded !== submenu ? (this.expanded = submenu) : (this.expanded = undefined);

    // Always return false in order to stop propagation of the click that would otherwise cause the sidenav to close.

    return false;
  }
}
