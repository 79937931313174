import { Routes } from '@angular/router';

import { NotFoundComponent } from '@mushindo/common';

import { HomeComponent, PrivacyPolicyComponent, TermsOfUseComponent } from './components';

export const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
  },
  {
    path: 'index.html',
    component: HomeComponent,
  },
  {
    path: 'karate',
    loadChildren: () => import('./features/karate/karate.routes').then(m => m.routes),
  },
  {
    path: 'kobudo',
    loadChildren: () => import('./features/kobudo/kobudo.routes').then(m => m.routes),
  },
  {
    path: 'klubben',
    loadChildren: () => import('./features/mushindo/mushindo.routes').then(m => m.routes),
  },
  {
    path: 'medlemmer',
    loadChildren: () => import('./features/medlemmer/medlemmer.routes').then(m => m.routes),
  },
  {
    path: 'trænere',
    loadChildren: () => import('./features/trænere/trænere.routes').then(m => m.routes),
  },
  {
    path: 'privacy-policy',
    component: PrivacyPolicyComponent,
  },
  {
    path: 'terms-of-use',
    component: TermsOfUseComponent,
  },
  {
    path: '**',
    component: NotFoundComponent,
  },
];
