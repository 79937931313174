import * as i0 from '@angular/core';
import { Component, ViewEncapsulation, Input } from '@angular/core';

// Import Angular stuff
const _c0 = ["*"];
class LayoutComponent {
  static ɵfac = function LayoutComponent_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || LayoutComponent)();
  };
  static ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
    type: LayoutComponent,
    selectors: [["page-layout"]],
    hostAttrs: [1, "page-layout"],
    ngContentSelectors: _c0,
    decls: 1,
    vars: 0,
    template: function LayoutComponent_Template(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵprojectionDef();
        i0.ɵɵprojection(0);
      }
    },
    styles: [".page-layout{display:flex;flex-direction:column;width:100%;height:calc(100vh - 64px);overflow-x:hidden;overflow-y:scroll}@media (max-width: 600px){.page-layout{height:calc(100vh - 56px)}}\n"],
    encapsulation: 2
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(LayoutComponent, [{
    type: Component,
    args: [{
      selector: 'page-layout',
      encapsulation: ViewEncapsulation.None,
      host: {
        'class': 'page-layout'
      },
      template: "<ng-content></ng-content>\r\n",
      styles: [".page-layout{display:flex;flex-direction:column;width:100%;height:calc(100vh - 64px);overflow-x:hidden;overflow-y:scroll}@media (max-width: 600px){.page-layout{height:calc(100vh - 56px)}}\n"]
    }]
  }], null, null);
})();

// Import Angular stuff
class HeaderComponent {
  color = 'none';
  static ɵfac = function HeaderComponent_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || HeaderComponent)();
  };
  static ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
    type: HeaderComponent,
    selectors: [["page-header"], ["header"]],
    hostAttrs: [1, "page-header"],
    inputs: {
      color: "color"
    },
    ngContentSelectors: _c0,
    decls: 1,
    vars: 0,
    template: function HeaderComponent_Template(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵprojectionDef();
        i0.ɵɵprojection(0);
      }
    },
    styles: [".page-header{background-color:var(--mat-sys-primary-container);color:var(--mat-sys-on-primary-container)}.page-header[color=primary]{background-color:var(--mat-sys-primary);color:var(--mat-sys-on-primary)}.page-header[color=tertiary]{background-color:var(--mat-sys-tertiary);color:var(--mat-sys-on-tertiary)}.page-header{width:calc(100% - 32px);padding:16px;flex:0 0 auto;display:flex}.page-header h1{font:var(--mat-sys-headline-large)}.page-header h2{font:var(--mat-sys-headline-small)}.page-header>*{margin-block:0px;margin-inline:auto}\n"],
    encapsulation: 2
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(HeaderComponent, [{
    type: Component,
    args: [{
      selector: 'page-header, header',
      encapsulation: ViewEncapsulation.None,
      host: {
        'class': 'page-header'
      },
      template: "<ng-content></ng-content>\r\n",
      styles: [".page-header{background-color:var(--mat-sys-primary-container);color:var(--mat-sys-on-primary-container)}.page-header[color=primary]{background-color:var(--mat-sys-primary);color:var(--mat-sys-on-primary)}.page-header[color=tertiary]{background-color:var(--mat-sys-tertiary);color:var(--mat-sys-on-tertiary)}.page-header{width:calc(100% - 32px);padding:16px;flex:0 0 auto;display:flex}.page-header h1{font:var(--mat-sys-headline-large)}.page-header h2{font:var(--mat-sys-headline-small)}.page-header>*{margin-block:0px;margin-inline:auto}\n"]
    }]
  }], null, {
    color: [{
      type: Input
    }]
  });
})();

// Import Angular stuff
class FooterComponent {
  color = 'none';
  static ɵfac = function FooterComponent_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || FooterComponent)();
  };
  static ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
    type: FooterComponent,
    selectors: [["page-footer"], ["footer"]],
    hostAttrs: [1, "page-footer"],
    inputs: {
      color: "color"
    },
    ngContentSelectors: _c0,
    decls: 1,
    vars: 0,
    template: function FooterComponent_Template(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵprojectionDef();
        i0.ɵɵprojection(0);
      }
    },
    styles: [".page-footer{background-color:var(--mat-sys-primary-container);color:var(--mat-sys-on-primary-container)}.page-footer[color=primary]{background-color:var(--mat-sys-primary);color:var(--mat-sys-on-primary)}.page-footer[color=tertiary]{background-color:var(--mat-sys-tertiary);color:var(--mat-sys-on-tertiary)}.page-footer{width:calc(100% - 32px);margin:auto;padding:16px;flex:0 0 auto}\n"],
    encapsulation: 2
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(FooterComponent, [{
    type: Component,
    args: [{
      selector: 'page-footer, footer',
      encapsulation: ViewEncapsulation.None,
      host: {
        'class': 'page-footer'
      },
      template: "<ng-content></ng-content>\r\n",
      styles: [".page-footer{background-color:var(--mat-sys-primary-container);color:var(--mat-sys-on-primary-container)}.page-footer[color=primary]{background-color:var(--mat-sys-primary);color:var(--mat-sys-on-primary)}.page-footer[color=tertiary]{background-color:var(--mat-sys-tertiary);color:var(--mat-sys-on-tertiary)}.page-footer{width:calc(100% - 32px);margin:auto;padding:16px;flex:0 0 auto}\n"]
    }]
  }], null, {
    color: [{
      type: Input
    }]
  });
})();

// Import Angular stuff
class ContentComponent {
  static ɵfac = function ContentComponent_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || ContentComponent)();
  };
  static ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
    type: ContentComponent,
    selectors: [["page-content"], ["page-main"], ["main"]],
    hostAttrs: [1, "page-content"],
    ngContentSelectors: _c0,
    decls: 1,
    vars: 0,
    template: function ContentComponent_Template(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵprojectionDef();
        i0.ɵɵprojection(0);
      }
    },
    styles: [".page-content{width:calc(100% - 32px);margin:auto;padding:16px;flex:1 1 auto}\n"],
    encapsulation: 2
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ContentComponent, [{
    type: Component,
    args: [{
      selector: 'page-content, page-main, main',
      encapsulation: ViewEncapsulation.None,
      host: {
        'class': 'page-content'
      },
      template: "<ng-content></ng-content>\r\n",
      styles: [".page-content{width:calc(100% - 32px);margin:auto;padding:16px;flex:1 1 auto}\n"]
    }]
  }], null, null);
})();

/**
 * Public surface API of @public/core/ui/layout
 */

/**
 * Generated bundle index. Do not edit.
 */

export { ContentComponent, FooterComponent, HeaderComponent, LayoutComponent };
