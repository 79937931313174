import { enableProdMode } from '@angular/core';
import { bootstrapApplication } from '@angular/platform-browser';

import { RootComponent } from '@public/core';

import { environment } from './environments/environment';
import { rootConfig } from './app/root.config';

if (environment.production) {
  enableProdMode();
}

bootstrapApplication(RootComponent, rootConfig);
