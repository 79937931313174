// Import Angular stuff

import { Component, Inject, OnDestroy, OnInit } from '@angular/core';

import { BreakpointObserver, BreakpointState, Breakpoints } from '@angular/cdk/layout';

import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule, MatDialogRef, MAT_DIALOG_DATA  } from '@angular/material/dialog';

// Import RxJS stuff

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { environment } from '../../environments/environment';

@Component({
  templateUrl: './about-dialog.component.html',
  styleUrls: ['./about-dialog.component.scss'],
  imports: [ MatButtonModule, MatDialogModule]
})
export class AboutDialogComponent implements OnInit, OnDestroy {
  protected readonly environment = environment;

  protected isXsmall = false;

  private unsubscriber = new Subject<void>();

  constructor(
    private dialog: MatDialogRef<AboutDialogComponent>,
    private breakpointObserver: BreakpointObserver,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  /* Life cycle hooks */

  public ngOnInit() {
    this.breakpointObserver
      .observe(Breakpoints.XSmall)
      .pipe(takeUntil(this.unsubscriber))
      .subscribe((state: BreakpointState) => {
        this.isXsmall = state.matches;
      });
  }

  public ngOnDestroy(): void {
    this.unsubscriber.next();
    this.unsubscriber.complete();
  }

  public ok() {
    this.dialog.close();
  }
}
